import axios from 'axios';
import auth from '../store/modules/auth.store';
import { IError } from '@/interfaces/error.interface';
var ErrorCodes = IError.ErrorCodes;
import store from '../store/index';
import Vue from 'vue';
export class AppError extends Error {
    constructor(m, errorData) {
        super(m);
        Object.setPrototypeOf(this, AppError.prototype);
        this.errorData = errorData;
    }
}
axios.interceptors.request.use((config) => {
    const token = store.state.auth.token
        ? store.state.auth.token
        : store.state.auth.patientToken
            ? store.state.auth.patientToken
            : Vue.$cookies.get('token');
    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
let refreshRequestIsComing = false;
axios.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    const originalRequest = error.config;
    const appError = new AppError(error.response.data.detail, error.response.data);
    console.log('error_code', appError.errorData.error_code);
    if ((appError.errorData.error_code === ErrorCodes.TOKEN_NOT_VALID ||
        appError.errorData.error_code === ErrorCodes.AUTHENTICATION_FAILED) &&
        !originalRequest._retry) {
        if (!refreshRequestIsComing) {
            originalRequest._retry = true;
            refreshRequestIsComing = true;
            await store.dispatch('auth/updateAccessToken');
            error.config.headers['Authorization'] = 'Bearer ' + auth.state.token;
            refreshRequestIsComing = false;
        }
        try {
            const response = await axios.request(error.config);
            return response;
        }
        catch (error) {
            return Promise.reject(appError);
        }
    }
    return Promise.reject(appError);
});
