import Vue from 'vue';
import { cookeTokenKey, IAuthForOtherUser, } from '@/interfaces/auth.interface';
import * as $api from '@/api/_api.js';
import router from '@/router';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { showClientError } from '@/utils/showNotify.js';
export default {
    namespaced: true,
    state: {
        isLabstoryPatient: false,
        userAccountInfo: {},
        currentAuthStep: IAuthForOtherUser.RegistrationSteps.CHECK_USER,
        token: null,
        patientToken: '',
        cabinets: [],
        isRefreshing: false,
        refreshingCall: null,
        patient: null,
    },
    mutations: {
        setTokens(state, { access, refresh }) {
            Vue.$cookies.remove('token');
            state.token = access;
            Vue.$cookies.set('token', access);
            if (refresh) {
                Vue.$cookies.set(cookeTokenKey, refresh);
            }
        },
        setPropertyInStore(state, { name, value }) {
            Vue.set(state, name, value);
        },
        setUserAccountInfo(state, user) {
            state.userAccountInfo = user;
        },
        setPatientToken(state, patientToken) {
            state.patientToken = patientToken;
        },
        setCabinets(state, cabinets) {
            state.cabinets = cabinets;
        },
        setMedcard() { },
    },
    actions: {
        async checkUser({ commit }, { value, type }) {
            const user = await $api.auth.checkUser({
                [type]: value,
            });
            commit('setUserAccountInfo', user);
            return true;
        },
        async sendCheckCode({ dispatch }, { value, type, reason }) {
            const { data } = await $api.auth.checkUser({
                [type]: value,
                reason,
            });
            return data;
        },
        async authUser({ commit, dispatch }, { authData, authType, }) {
            try {
                const data = await $api.auth.authUser({ authData, authType });
                commit('setTokens', { ...data });
                console.log(2);
                dispatch('getAllStaticVariables');
                if (data.patient) {
                    commit('personalArea/setPatient', data.patient, { root: true });
                }
                if (data?.cabinets) {
                    commit('setCabinets', data.cabinets);
                    router.push({ name: ROUTE_NAME.CHANGE_CABINETS });
                }
                // checkUserRequiredData();
                return true;
            }
            catch (error) {
                showClientError(error.message);
            }
        },
        authUser_new({ commit, dispatch }, { access, refresh, patient, role }) {
            commit('setTokens', { access, refresh });
            dispatch('getAllStaticVariables');
            if (patient) {
                commit('personalArea/setPatient', patient, { root: true });
                // checkUserRequiredData();
            }
        },
        getAllStaticVariables({ dispatch }) {
            dispatch('dashboard/getOverview', null, { root: true });
            dispatch('staticVariables/getStaticVariables', null, { root: true });
            dispatch('staticVariables/getLaboratories', null, { root: true });
            dispatch('staticVariables/getUnits', null, { root: true });
            dispatch('staticVariables/getOffersAndDiscountCounter', null, { root: true });
        },
        async loginById({ commit, dispatch }, loginData) {
            const data = await $api.auth.loginById(loginData);
            commit('setPatientToken', data.patient_token);
            if (data?.email) {
                commit('personalArea/setPatientProperty', { name: 'email', value: data.email }, { root: true });
            }
            if (data?.phone) {
                commit('personalArea/setPatientProperty', { name: 'phone', value: data.phone }, { root: true });
            }
            return true;
        },
        async updateAccessToken({ commit, dispatch }) {
            const refresh = Vue.$cookies.get(cookeTokenKey);
            if (refresh) {
                try {
                    const data = await $api.auth.refreshToken({ refresh });
                    commit('setTokens', { ...data });
                    dispatch('getAllStaticVariables');
                    return data;
                }
                catch (error) {
                    console.dir('refreshTokenError');
                    console.dir(error);
                    if (error.errorData.error_code === 'invalid_token') {
                        dispatch('logOut');
                        router.push({ name: 'loginPage' });
                    }
                    return false;
                }
            }
        },
        async changePatientsData({ commit, dispatch }, { changedData }) {
            const data = $api.patient.changePatient(changedData);
            commit('personalArea/setPatient', data, { root: true });
            return true;
        },
        async changeCabinet({ commit }, cabinetId) {
            const data = await $api.auth.changeCabinet({ user_id: cabinetId });
            commit('setTokens', { ...data });
            dispatch('getAllStaticVariables');
            if (data.patient) {
                commit('personalArea/setPatient', data.patient, { root: true });
            }
            router.push({ name: ROUTE_NAME.INDEX_PAGE });
        },
        logOut({ commit }) {
            commit('setPatientToken', null);
            Vue.$cookies.remove(cookeTokenKey);
            Vue.$cookies.remove('token');
            Vue.$cookies.remove('refreshToken');
            commit('personalArea/setPropertyInStore', {
                name: 'medicalCard',
                value: {
                    is_pregnant_now: false,
                    last_menstruation_date: new Date(),
                    pregnancies_count: null,
                    is_abortion: false,
                    is_miscarriages: null,
                    menstruation_start: [],
                    menstruation_duration: [],
                    menstruation_cycle: [],
                    hormonal_drugs: [],
                    allergies: [],
                    gender: null,
                    blood_group: [],
                    country: null,
                    region_type: null,
                    birth_date: null,
                    created_at: null,
                    deleted_at: null,
                    updated_at: null,
                    race: [],
                    weight: null,
                    height: null,
                    all_pregnancies_success: null,
                    is_pregnancies: false,
                    is_undeveloped_pregnancy: null,
                },
            }, { root: true });
            router.push({ name: 'loginPage' });
            commit('personalArea/setPatient', null, { root: true });
        },
    },
    getters: {
        isLoggedIn: (state) => !!Vue.$cookies.get(cookeTokenKey) || !!state.token,
    },
};
