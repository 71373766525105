<template>
<div class="lockLabel" :style="{color: white ? 'white' : ''}">
  <svg class="lockLabel-locked" v-if="locked" width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.07352 3.49153V4.48369C10.188 4.83156 11 5.84044 11 7.04544V10.2399C11 11.7317 9.76324 12.9412 8.23849 12.9412H2.76216C1.23676 12.9412 0 11.7317 0 10.2399V7.04544C0 5.84044 0.812672 4.83156 1.92648 4.48369V3.49153C1.93305 1.56251 3.53078 0 5.49014 0C7.47579 0 9.07352 1.56251 9.07352 3.49153ZM5.50328 1.12527C6.838 1.12527 7.92288 2.18624 7.92288 3.49154V4.34417H3.07709V3.47868C3.08367 2.17981 4.16855 1.12527 5.50328 1.12527ZM6.0753 9.3532C6.0753 9.66827 5.81887 9.91904 5.4967 9.91904C5.1811 9.91904 4.92467 9.66827 4.92467 9.3532V7.92572C4.92467 7.61708 5.1811 7.3663 5.4967 7.3663C5.81887 7.3663 6.0753 7.61708 6.0753 7.92572V9.3532Z" :fill="white ? 'white' : 'currentColor'"/>
  </svg>
  <svg class="lockLabel-unlocked" v-else width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.05804 4.34245H8.23784C9.76324 4.34245 11 5.55219 11 7.04427V10.2394C11 11.7314 9.76324 12.9412 8.23784 12.9412H2.76151C1.23676 12.9412 0 11.7314 0 10.2394V7.04427C0 5.81716 0.841602 4.79265 1.98697 4.46336L1.9153 4.47172V3.4858C1.92845 1.56346 3.52684 0 5.48619 0C7.01817 0 8.37197 0.946053 8.87167 2.34745C8.92427 2.48894 8.91112 2.64329 8.84537 2.77835C8.77962 2.9134 8.66193 3.01631 8.51662 3.06197C8.22074 3.16423 7.89199 3.01052 7.78022 2.71468C7.44489 1.76219 6.52505 1.12549 5.49934 1.12549C4.15738 1.12549 3.07316 2.18087 3.06593 3.47293V4.34117L3.05804 4.34245ZM5.4967 9.91843C5.81888 9.91843 6.0753 9.66761 6.0753 9.35247V7.92471C6.0753 7.61601 5.81888 7.36518 5.4967 7.36518C5.1811 7.36518 4.92468 7.61601 4.92468 7.92471V9.35247C4.92468 9.66761 5.1811 9.91843 5.4967 9.91843Z" :fill="white ? 'white' : 'currentColor'"/>
  </svg>
  <slot/>
</div>
</template>

<script>
export default {
  data() {
    return{

    }
  },
  props: {
    locked: {
      type: Boolean
    },
    white: {
      type: Boolean
    },
    
  }
}
</script>

<style lang="scss">
.lockLabel{
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 6px;
  color: $grey-2;
  svg {
    color: $accent-color;
  }
}
</style>