export var IAnalyzes;
(function (IAnalyzes) {
    let BusEvents;
    (function (BusEvents) {
        BusEvents["OPEN_ADD_ANALYZES_MODAL"] = "addAnalyzesModal:open";
        BusEvents["OPEN_ADD_ANALYZES_COMMENT"] = "addAnalyzesComment:open";
        BusEvents["EDIT_ANALYZES"] = "analyzes:edit";
        BusEvents["SET_CATEGORY"] = "category:set";
        BusEvents["SHOW_FILTER"] = "filter:show";
        BusEvents["ADD_RESEARCH_OPEN"] = "research:open";
        BusEvents["PASSWORD_CHANGED"] = "password:changed";
        BusEvents["TOGGLE_DOCUMENT_MODAL"] = "documentModal:toggle";
    })(BusEvents = IAnalyzes.BusEvents || (IAnalyzes.BusEvents = {}));
    let TabsName;
    (function (TabsName) {
        TabsName["ALL_ANALYZES"] = "allAnalyzes";
        TabsName["BY_CATEGORY"] = "byCategory";
    })(TabsName = IAnalyzes.TabsName || (IAnalyzes.TabsName = {}));
})(IAnalyzes || (IAnalyzes = {}));
