/* eslint-disable */
import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import { IRouter } from '@/interfaces/router.interface';
import { ILayout } from '@/interfaces/layout.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
var ROUTE_PATH = IRouter.ROUTE_PATH;
var Breakpoint = ILayout.Breakpoint;
Vue.use(Router);
export const MobileRouteRedirect = (to, from, next) => {
    if (window.screen.width >= Breakpoint.MOBILE) {
        next({ name: ROUTE_NAME.PAGE_PERSONAL_AREA });
    }
    else {
        next();
    }
};
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: ROUTE_PATH[ROUTE_NAME.PAGE_PERSONAL_AREA],
            name: ROUTE_NAME.PAGE_PERSONAL_AREA,
            component: () => import('@/views/PersonalArea/index.vue'),
            props: (route) => ({ query: route.query.activeTab }),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.MEDICAL_CARD_PAGE],
            name: ROUTE_NAME.MEDICAL_CARD_PAGE,
            component: () => import('@/views/PersonalArea/medicalCard/MedicalCard.vue'),
            beforeEnter: MobileRouteRedirect,
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.PROFILE_PAGE],
            name: ROUTE_NAME.PROFILE_PAGE,
            component: () => import('@/views/PersonalArea/userSetting/UserSettings.vue'),
            beforeEnter: MobileRouteRedirect,
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.PASSWORD_CHANGE_PAGE],
            name: ROUTE_NAME.PASSWORD_CHANGE_PAGE,
            component: () => import('@/views/PasswordChangePage.vue'),
            beforeEnter: MobileRouteRedirect,
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.INDEX_PAGE],
            name: ROUTE_NAME.INDEX_PAGE,
            component: () => import('@/views/DashboardPage/index.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.ANALYZES_PAGE],
            name: ROUTE_NAME.ANALYZES_PAGE,
            component: () => import('@/views/AnalyzePage/index.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.STORAGE_PAGE],
            name: ROUTE_NAME.STORAGE_PAGE,
            component: () => import('@/views/DocumentsPage/index.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.STORAGE_SINGLE_DOCUMENT_PAGE],
            name: ROUTE_NAME.STORAGE_SINGLE_DOCUMENT_PAGE,
            component: () => import('@/views/DocumentPage/index.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.APPOINTMENT_PAGE],
            name: ROUTE_NAME.APPOINTMENT_PAGE,
            component: () => import('@/views/AppointmentPage.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.NOT_FOUND_PAGE],
            name: ROUTE_NAME.NOT_FOUND_PAGE,
            component: () => import('@/views/NotFound.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.AUTH_PAGE],
            name: ROUTE_NAME.AUTH_PAGE,
            component: () => import('@/views/LoginPage/index.vue'),
            meta: {
                layout: () => import('@/layouts/LoginLayout.vue'),
            },
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.CHANGE_CABINETS],
            name: ROUTE_NAME.CHANGE_CABINETS,
            component: () => import('@/views/ChangeCabinets.vue'),
            meta: {
                layout: ILayout.LayoutType.AUTH,
            },
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.ADD_ANALYZES],
            name: ROUTE_NAME.ADD_ANALYZES,
            component: () => import('@/views/AddAnalyzesPage.vue'),
            beforeEnter: MobileRouteRedirect,
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.ALL_ANALYZES],
            name: ROUTE_NAME.ALL_ANALYZES,
            component: () => import('@/views/AnalyzesPage/index.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.ORDER_HISTORY],
            name: ROUTE_NAME.ORDER_HISTORY,
            component: () => import('@/views/OrdersPage.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.ORDER_PAGE],
            name: ROUTE_NAME.ORDER_PAGE,
            component: () => import('@/views/OrderPage.vue'),
        },
        {
            path: ROUTE_PATH[ROUTE_NAME.WELCOME_PAGE],
            name: ROUTE_NAME.WELCOME_PAGE,
            component: () => import('@/views/WelcomePage.vue'),
            meta: {
                layout: ILayout.LayoutType.AUTH,
            },
        },
        {
            path: '/test',
            name: 'testPage',
            component: () => import('@/views/TestPage.vue'),
        },
        {
            path: '/no-connection',
            name: 'noConnection',
            component: () => import('@/views/NoConnectionPage.vue'),
        },
        {
            path: '/delete-account',
            name: 'deleteAccount',
            component: () => import('@/views/DeleteAccountPage.vue'),
        },
        {
            path: '/login-page',
            name: 'loginPage',
            component: () => import('@/views/LoginPage/index.vue'),
            meta: {
                layout: () => import('@/layouts/LoginLayout.vue'),
            },
        },
        {
            path: '/tariffs',
            name: 'tariffsPage',
            component: () => import('@/views/TariffsPage/TariffsPage.vue'),
        },
        {
            path: '/payment',
            name: 'paymentPage',
            component: () => import('@/views/PaymentPage/PaymentPage.vue'),
        },
        {
            path: '/discount',
            name: 'discountPage',
            component: () => import('@/views/DiscountPage/DiscountPage.vue'),
        },
        {
            path: '/pillbox',
            name: 'pillboxPage',
            component: () => import('@/views/PillboxPage/PillboxPage.vue'),
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
router.beforeEach((to, from, next) => {
    const pushToEditData = store.state.personalArea.userMustSetPersonalData &&
        to.fullPath !== '/personal-area?activeTab=medical-card' &&
        to.name !== 'loginPage';
    // next();
    const isOnline = window.navigator.onLine;
    if (isOnline) {
        pushToEditData ? next('/personal-area?activeTab=medical-card') : next();
    }
    else {
        to.name === 'noConnection' ? next() : next({ name: 'noConnection' });
    }
});
export default router;
