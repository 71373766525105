import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
let DialogModal = class DialogModal extends Vue {
    closeModal() {
        return false;
    }
};
__decorate([
    Prop({ required: true })
], DialogModal.prototype, "isDialogModalOpen", void 0);
__decorate([
    Prop()
], DialogModal.prototype, "title", void 0);
__decorate([
    Prop()
], DialogModal.prototype, "btn2Text", void 0);
__decorate([
    Emit('close-modal')
], DialogModal.prototype, "closeModal", null);
DialogModal = __decorate([
    Component({
        components: { MainBtn },
    })
], DialogModal);
export default DialogModal;
