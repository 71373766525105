import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
let MainSelect = class MainSelect extends Vue {
    constructor() {
        super(...arguments);
        this.isIconReverse = false;
        this.qMenuWidth = 0;
    }
    inputSelect(value) {
        return value;
    }
    mounted() {
        this.qMenuWidth = this.$refs.select.$el?.offsetWidth;
    }
    popupShow() {
        this.isIconReverse = true;
    }
    popupHide() {
        this.isIconReverse = false;
    }
};
__decorate([
    Prop({ required: true })
], MainSelect.prototype, "value", void 0);
__decorate([
    Prop({ required: true })
], MainSelect.prototype, "options", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "optionValue", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "optionLabel", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "multiple", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "labelTitle", void 0);
__decorate([
    Prop({ default: '' })
], MainSelect.prototype, "placeholder", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "selectLabel", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "disabled", void 0);
__decorate([
    Prop({ default: 'transparent' })
], MainSelect.prototype, "bcgColor", void 0);
__decorate([
    Prop({ default: 'none' })
], MainSelect.prototype, "borderColor", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "width", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "height", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "minWidth", void 0);
__decorate([
    Prop()
], MainSelect.prototype, "maxWidth", void 0);
__decorate([
    Prop({ type: Boolean })
], MainSelect.prototype, "disableOffset", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], MainSelect.prototype, "mapOptions", void 0);
__decorate([
    Prop({ default: '' })
], MainSelect.prototype, "optionsLabel", void 0);
__decorate([
    Emit('input-select')
], MainSelect.prototype, "inputSelect", null);
MainSelect = __decorate([
    Component({
        components: { CheckboxInput },
    })
], MainSelect);
export default MainSelect;
