import request from '@/utils/request.js';

export const getMedCard = async () => {
  const res = await request({
    method: 'get',
    url: `/medcard/`,
  });
  return res.data;
};

export const changeMedCard = async (data) => {
  const res = await request({
    method: 'patch',
    url: `/medcard/`,
    data,
  });
  return res.data;
};

export const addReactionItem = async ({ endpoint, data }) => {
  const res = await request({
    method: 'post',
    url: `/medcard/${endpoint}/`,
    data,
  });
  return res.data;
};

export const updateReactionItem = async ({ endpoint, id, data }) => {
  const res = await request({
    method: 'patch',
    url: `/medcard/${endpoint}/${id}/`,
    data,
  });
  return res.data;
};

export const deleteReactionItem = async ({ endpoint, id }) => {
  const res = await request({
    method: 'delete',
    url: `/medcard/${endpoint}/${id}/`,
  });
  return res.data;
};
