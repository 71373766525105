import Notify from 'quasar/src/plugins/Notify.js';
;
export default {
    namespaced: true,
    state: {
        isVisible: true,
        payload: null,
    },
    mutations: {
        closeErrorModal() {
            return true;
            // @todo modal logic
        },
        openErrorModal() {
            return true;
            // @todo modal logic
        },
    },
    actions: {
        closeErrorModal({ commit }) {
            commit('close');
        },
        openErrorModal({ commit }) {
            commit('close');
        },
        showErrorNotice({}, { message, closeBtn, onDismiss }) {
            // TODO временный костыль до фатального рефакторинга, исключает ошибки которые не надо выводить
            console.log('ошибка тест');
            console.log(message);
            const exceptErrors = [
                "detail: Given token not valid for any token type; code: token_not_valid; messages: {'token_class': ErrorDetail(string='AccessToken', code='token_not_valid'), 'token_type': ErrorDetail(string='access', code='token_not_valid'), 'message': ErrorDetail(string='Token is invalid or expired', code='token_not_valid')}",
                `detail: "detail: Given token not valid for any token type; code: token_not_valid; messages: {'token_class': ErrorDetail(string='AccessToken', code='token_not_valid'), 'token_type': ErrorDetail(string='access', code='token_not_valid'), 'message': ErrorDetail(string='Token is invalid or expired', code='token_not_valid')}"`,
                `detail: Given token not valid for any token type; code: token_not_valid; messages: {'token_class': ErrorDetail(string='AccessToken', code='token_not_valid'), 'token_type': ErrorDetail(string='access', code='token_not_valid'), 'message': ErrorDetail(string='Token is invalid or expired', code='token_not_valid')}`,
                'Учетные данные не были предоставлены.',
                'detail: Пользователь не найден.; code: user_not_found',
            ];
            if (!exceptErrors.includes(message)) {
                Notify.create({
                    message: message,
                    type: 'custom-error',
                    closeBtn,
                    onDismiss,
                });
            }
        },
        async defaultErrorHandler({ dispatch }, { error }) {
            if (error.message) {
                dispatch('showErrorNotice', { message: error.message });
            }
        },
    },
};
