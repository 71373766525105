import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let BackBtn = class BackBtn extends Vue {
    goBack() {
        return false;
    }
};
__decorate([
    Prop({ default: 1 })
], BackBtn.prototype, "type", void 0);
__decorate([
    Emit('go-back')
], BackBtn.prototype, "goBack", null);
BackBtn = __decorate([
    Component({})
], BackBtn);
export default BackBtn;
