import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { showClientError } from '@/utils/showNotify.js';
let UserCardAvatar = class UserCardAvatar extends Vue {
    constructor() {
        super(...arguments);
        this.url = '@/assets/image-placeholder-350x350.png';
    }
    uploadPhoto() {
        this.$refs.uploader.click();
    }
    get avatar() {
        return this.$store.state.personalArea.patient?.avatar || require('@/assets/image-placeholder-350x350.png');
    }
    onFileGet(fileList) {
        const formData = new FormData();
        formData.append('avatar', fileList[0], fileList[0].name);
        const type = fileList[0].type;
        const validTypes = ['image/jpeg', 'image/jpg', "image/png",];
        if (!validTypes.includes(type)) {
            showClientError('Неверный формат изображения');
            return;
        }
        this.$store.dispatch('personalArea/changeAvatar', formData);
    }
};
UserCardAvatar = __decorate([
    Component({})
], UserCardAvatar);
export default UserCardAvatar;
