import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import UserCardAvatar from '@/views/PersonalArea/userCard/UserCardAvatar.vue';
import { IPersonalArea } from '@/interfaces/personal-area.interface';
var TabsName = IPersonalArea.TabsName;
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { format, differenceInYears } from 'date-fns';
import PersonalAreaMixin from '@/mixins/personal-area-mixins';
import { pluralizeText } from '@/utils/utils';
let UserCard = class UserCard extends PersonalAreaMixin {
    constructor() {
        super(...arguments);
        this.ROUTE_NAME = ROUTE_NAME;
        this.tabsName = TabsName;
    }
    async created() {
        await this.$store.dispatch('personalArea/init');
    }
    get sections() {
        const val = this.overview;
        return [
            { label: 'В норме', value: val.in_norm || 0, color: '#63C58A' },
            { label: 'Пограничные зоны', value: val.border_zone || 0, color: '#FFDE79' },
            { label: 'Не в норме', value: val.out_of_norm || 0, color: '#FF7C7C' },
        ];
    }
    get sectionsTotal() {
        return this.sections.map((item) => item.value)?.reduce((acc, val) => acc + val);
    }
    get medCard() {
        return this.$store.state.personalArea.medicalCard;
    }
    get fullName() {
        let { name = '', surname = '' } = this.patient;
        name = name ? `${name[0]?.toUpperCase()}${name.slice(1)?.toLowerCase()}` : '-';
        surname = surname ? `${surname[0]?.toUpperCase()}${surname.slice(1)?.toLowerCase()}` : '-';
        return `${name} ${surname}`;
    }
    get birthDate() {
        const age = differenceInYears(new Date(), new Date(this.medCard.birth_date));
        const ru = ['год', 'года', 'лет'];
        return `${age} ${pluralizeText(age, ru)}, ${format(new Date(this.medCard.birth_date), 'dd.MM.yyyy')}`;
    }
    get overview() {
        return this.$store.state.dashboard.overview;
    }
    popupClose() {
        if (this.onCLose)
            this.onCLose();
    }
    logOut() {
        this.$store.dispatch('auth/logOut');
    }
    copyId(text) {
        navigator.clipboard.writeText(text);
    }
};
__decorate([
    Prop()
], UserCard.prototype, "onCLose", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], UserCard.prototype, "isPopup", void 0);
UserCard = __decorate([
    Component({
        components: {
            UserCardAvatar,
        },
    })
], UserCard);
export default UserCard;
