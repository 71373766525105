import request from '@/utils/request.js';

export const getStaticVariables = async () => {
  const res = await request({
    method: 'get',
    url: `static-variables/`,
  });
  return res.data;
};

export const getMapAdresses = async () => {
  const res = await request({
    method: 'get',
    url: `static-variables/map-addresses/`,
  });
  return res.data.map_addresses;
};
