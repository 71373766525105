import { subDays } from 'date-fns';
export const recomendationTypes = {
    good: {
        title: 'Все отлично!',
        text: 'По нашим данным вы в прекрасном состоянии — так держать 😉',
        image: 'state-positive.svg',
    },
    norm: {
        title: 'Как самочувствие?',
        text: 'Кажется, несколько показателей выбились из нормы. Главное — вовремя заметить 😉',
        image: 'state-warning.svg',
    },
    bad: {
        title: 'Вашему организму нужно внимание',
        text: 'Ваши показатели находятся преимущественно за пределами нормы. Пожалуйста, не откладывайте визит к врачу, здоровье — это очень важно.',
        image: 'state-danger.svg',
    },
    noResults: {
        title: 'Добро пожаловать!',
        text: 'У нас пока нет информации о ваших анализах, но как только она появится — вы все увидите здесь 😉',
        image: 'state-no-results.svg',
    },
};
export const periodList = [
    { id: 1, text: '3 м.' },
    { id: 2, text: '6 м.' },
    { id: 3, text: '1 год' },
    { id: 4, text: 'Весь период' },
];
export const mobilePeriodList = [
    { id: 0, text: 'Неделя', dateRange: [subDays(new Date(), 7), new Date()] },
    { id: 1, text: 'Месяц', dateRange: [subDays(new Date(), 30), new Date()] },
    { id: 2, text: 'Квартал', dateRange: [subDays(new Date(), 90), new Date()] },
    { id: 3, text: 'Год', dateRange: [subDays(new Date(), 365), new Date()] },
];
export const points = [
    [0, 5],
    [Math.PI * 0.25, 2.5],
    [Math.PI * 0.5, 5],
    [Math.PI * 0.75, 2.5],
    [Math.PI, 5],
    [Math.PI * 1.25, 2.5],
    [Math.PI * 1.5, 5],
    [Math.PI * 1.75, 2.5],
    [Math.PI * 2, 5],
];
export const langConfig = {
    decimal: ',',
    thousands: '\xa0',
    grouping: [3],
    currency: ['', ' руб.'],
    dateTime: '%A, %e %B %Y г. %X',
    date: '%d.%m.%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
    shortDays: ['Вс.', 'Пн.', 'Вт.', 'Ср.', 'Чт.', 'Пт.', 'Сб.'],
    months: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ],
    shortMonths: [
        'Янв.',
        'Фев.',
        'Мар.',
        'Апр.',
        'Май.',
        'Июн.',
        'Июл.',
        'Авг.',
        'Сен.',
        'Окт.',
        'Ноя.',
        'Дек.',
    ],
};
export function pluralizeText(n, text_forms) {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return text_forms[2];
    }
    if (n1 > 1 && n1 < 5) {
        return text_forms[1];
    }
    if (n1 == 1) {
        return text_forms[0];
    }
    return text_forms[2];
}
export const hormonalDrugsData = {
    title: 'Прием гормональных препаратов',
    inputPlaceholder: 'Какой препарат принимаете?',
    areaPlaceholder: 'Опишите реакцию на препарат',
    property: 'hormonalDrugs',
    addFilesBtnText: 'Добавить новый препарат',
    addReactionBtnText: 'Добавить реакцию',
    endPoint: 'hormonal_drugs',
};
export const allergicReaction = {
    title: 'Аллергические реакции',
    inputPlaceholder: 'Введите аллергию',
    areaPlaceholder: '',
    property: 'allergicReactions',
    addFilesBtnText: 'Добавить аллергию',
    addReactionBtnText: 'Опишите реакцию',
    endPoint: 'allergies',
};
export const sortSelectOptionList = [
    { key: 'desc', text: 'По дате загрузки по убыванию' },
    { key: 'asc', text: 'По дате загрузки по возрастанию' },
    { key: 'labstory', text: 'Сначала лабстори' },
    { key: 'otherLabs', text: 'Сначала другие клиники' },
    { key: 'positive', text: 'Сначала положительные результаты' },
    { key: 'negative', text: 'Сначала отрицательные результаты' },
];
export const sortDocSelectOptionList = [
    { key: 'desc', text: 'По дате загрузки по убыванию' },
    { key: 'asc', text: 'По дате загрузки по возрастанию' },
    { key: 'dateDesc', text: 'По дате исследования по убыванию' },
    { key: 'dateAsc', text: 'По дате исследования по возрастанию' },
    { key: 'type', text: 'По типу исследования' },
    { key: 'allowedTrue', text: 'Сначала расшифрованные' },
    { key: 'allowedFalse', text: 'Сначала нерасшифрованные' },
];
export function countColor(result) {
    const green = '#63C58A';
    const red = '#FF7C7C';
    const value = result?.value;
    const min = result?.ranges?.min || -Infinity;
    const max = result?.ranges?.max || Infinity;
    if (value < min || value > max) {
        return red;
    }
    return green;
}
export function getImageLink(files) {
    if (!files.length)
        return '';
    const link = files[0].file_link;
    const re = /^.*\.(pdf|PDF)$/;
    return link.match(re) ? require('@/assets/PDF2.svg') : link;
}
export function parseRange(range) {
    if (!range)
        return { min: null, max: null };
    const arr = typeof range === 'string' && range?.match(/[]?(?=\.\d|\d)(?:\d+)?(?:\.?\d*)(?:[eE][]?\d+)?/g);
    const [min, max] = arr;
    return {
        min: min && !Number.isNaN(+min) ? +min : null,
        max: max && !Number.isNaN(+max) ? +max : null,
    };
}
export function chunkArray(array, chunkSize) {
    const arr = [];
    let i = 0;
    for (; i < array.length; i += chunkSize) {
        arr.push(array.slice(i, i + chunkSize));
    }
    return arr;
}
export const userFirstVisit = ({ id, localStorageItem }) => {
    const visitedArray = window.localStorage[localStorageItem];
    const parsedVisitedArray = visitedArray ? JSON.parse(visitedArray) : [];
    if (parsedVisitedArray.find((item) => {
        return item == id;
    })) {
        return false;
    }
    else {
        parsedVisitedArray.push(id);
        window.localStorage[localStorageItem] = JSON.stringify(parsedVisitedArray);
        return true;
    }
};
export const formatMoney = new Intl.NumberFormat('ru-RU').format;
export const paginationRequest = async ({ request, params, targetArray, $state, nested }) => {
    const res = await request(params);
    const nextResults = res[nested] || res;
    if (nextResults.length !== 0) {
        targetArray.push(...nextResults);
        $state?.loaded();
    }
    else {
        $state?.complete();
    }
    return res;
};
export const someOldUnitNotEqualUnit = (results, available_units) => {
    let notEqual = false;
    let oldUnit, jlabUnit = null;
    results?.some((result) => {
        oldUnit = { name: result.last_unit, id: result.last_unit_id, coeff: result.initial_coeff };
        jlabUnit = { name: result.unit, id: result.unit_id, coeff: 1 };
        notEqual = oldUnit.name !== jlabUnit.name && oldUnit.name !== null && jlabUnit.name !== null;
        return notEqual;
    });
    if (!notEqual) {
        oldUnit = null;
        jlabUnit = null;
    }
    return { notEqual, oldUnit, jlabUnit };
};
export const countRanges = (result) => {
    const { ranges } = result;
    return ranges.min !== null && ranges.max !== null
        ? `${ranges.min} - ${ranges.max}`
        : ranges.min !== null
            ? `${ranges.min} >`
            : `< ${ranges.max}`;
};
