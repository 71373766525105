import { getOverview } from '@/api/dashboard.js';
export default {
    namespaced: true,
    state: {
        overview: {},
        results: [],
    },
    mutations: {
        setOverview(state, payload) {
            state.overview = payload;
        },
    },
    actions: {
        async getOverview({ commit }) {
            const data = await getOverview();
            commit('setOverview', data);
        },
    },
};
