import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
// import AnalyzesCard from '@/components/analyzes/AnalyzesCard.vue';
import AddAnalyzes from '@/components/analyzes/AddAnalyzes.vue';
let AddAnalyzesModal = class AddAnalyzesModal extends Vue {
    onClose() {
        return false;
    }
};
__decorate([
    Prop({ required: true, default: () => false })
], AddAnalyzesModal.prototype, "isShow", void 0);
__decorate([
    Emit('close')
], AddAnalyzesModal.prototype, "onClose", null);
AddAnalyzesModal = __decorate([
    Component({
        components: {
            MainBtn,
            // AnalyzesCard,
            AddAnalyzes,
        },
    })
], AddAnalyzesModal);
export default AddAnalyzesModal;
