<template>
  <div class="loading-spinner">
    <q-spinner size="40px" :thickness="2.5"/>
    <div class="loading-spinner__text">{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      default: 'Загрузка...'
    }
  }
}
</script>

<style lang="scss">
  .loading-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $primary;
  }

  .loading-spinner__text {
    margin-top: 25px;
    font-size: 12px;
    line-height: 15px;
  }
</style>