export var IRouter;
(function (IRouter) {
    let ROUTE_NAME;
    (function (ROUTE_NAME) {
        ROUTE_NAME["PAGE_PERSONAL_AREA"] = "personalArea";
        ROUTE_NAME["INDEX_PAGE"] = "indexPage";
        ROUTE_NAME["ANALYZES_PAGE"] = "AnalyzePage";
        ROUTE_NAME["DYNAMICS_ANALYZES"] = "dynamicsAnalyzes";
        ROUTE_NAME["ADD_ANALYZES"] = "addAnalyzes";
        ROUTE_NAME["ALL_ANALYZES"] = "allAnalyzes";
        ROUTE_NAME["STORAGE_PAGE"] = "DocumentsPage";
        ROUTE_NAME["STORAGE_SINGLE_DOCUMENT_PAGE"] = "singleDocumentPage";
        ROUTE_NAME["APPOINTMENT_PAGE"] = "appointmentPage";
        ROUTE_NAME["NOT_FOUND_PAGE"] = "notFoundPage";
        ROUTE_NAME["PROFILE_PAGE"] = "profilePage";
        ROUTE_NAME["MEDICAL_CARD_PAGE"] = "medicalCart";
        ROUTE_NAME["PASSWORD_CHANGE_PAGE"] = "passwordChangePage";
        ROUTE_NAME["AUTH_PAGE"] = "authPage";
        ROUTE_NAME["CHANGE_CABINETS"] = "changeCabinets";
        ROUTE_NAME["ORDER_HISTORY"] = "orderHistory";
        ROUTE_NAME["ORDER_PAGE"] = "orderPage";
        ROUTE_NAME["WELCOME_PAGE"] = "welcomePage";
        ROUTE_NAME["DISCOUNT_PAGE"] = "discountPage";
    })(ROUTE_NAME = IRouter.ROUTE_NAME || (IRouter.ROUTE_NAME = {}));
    IRouter.ROUTE_PATH = {
        [ROUTE_NAME.INDEX_PAGE]: '/',
        [ROUTE_NAME.PAGE_PERSONAL_AREA]: '/personal-area',
        [ROUTE_NAME.ANALYZES_PAGE]: '/analyzes/:id',
        [ROUTE_NAME.DYNAMICS_ANALYZES]: '/dynamics-analyzes',
        [ROUTE_NAME.ADD_ANALYZES]: '/add-analyzes',
        [ROUTE_NAME.ALL_ANALYZES]: '/analyzes',
        [ROUTE_NAME.STORAGE_PAGE]: '/documents',
        [ROUTE_NAME.STORAGE_SINGLE_DOCUMENT_PAGE]: '/documents/:id',
        [ROUTE_NAME.APPOINTMENT_PAGE]: '/appointment',
        [ROUTE_NAME.NOT_FOUND_PAGE]: '*',
        [ROUTE_NAME.PROFILE_PAGE]: '/profile',
        [ROUTE_NAME.MEDICAL_CARD_PAGE]: '/medical-card',
        [ROUTE_NAME.PASSWORD_CHANGE_PAGE]: '/password-change',
        [ROUTE_NAME.AUTH_PAGE]: '/auth',
        [ROUTE_NAME.CHANGE_CABINETS]: '/change-cabinets',
        [ROUTE_NAME.ORDER_HISTORY]: '/orders',
        [ROUTE_NAME.ORDER_PAGE]: '/orders/:id',
        [ROUTE_NAME.WELCOME_PAGE]: '/welcome-page',
        [ROUTE_NAME.DISCOUNT_PAGE]: '/discount',
    };
})(IRouter || (IRouter = {}));
