import Vue from 'vue';
import * as $api from '@/api/_api.js';
export default {
    namespaced: true,
    state: {
        oms: '',
        dms: '',
        patient: {
            avatar: null,
            created_at: '',
            deleted_at: null,
            email: '',
            id: '',
            is_deleted: false,
            name: '',
            password: null,
            patronymic: '',
            phone: '',
            surname: '',
            updated_at: '',
            user_id: '',
        },
        medicalCard: {
            is_pregnant_now: false,
            last_menstruation_date: new Date(),
            pregnancies_count: null,
            is_abortion: false,
            is_miscarriages: null,
            menstruation_start: [],
            menstruation_duration: [],
            menstruation_cycle: [],
            hormonal_drugs: [],
            allergies: [],
            gender: null,
            blood_group: [],
            country: null,
            region_type: null,
            birth_date: null,
            created_at: null,
            deleted_at: null,
            updated_at: null,
            race: [],
            weight: null,
            height: null,
            all_pregnancies_success: null,
            is_pregnancies: false,
            is_undeveloped_pregnancy: null,
        },
        userMustSetPersonalData: false,
    },
    mutations: {
        setUserMustSetPersonalData(state) {
            state.userMustSetPersonalData = true;
        },
        setPropertyInStore(state, { name, value }) {
            Vue.set(state, name, value);
        },
        setPatientProperty(state, { name, value }) {
            Vue.set(state.patient, name, value);
        },
        setMedicalCardProperty(state, { name, value }) {
            Vue.set(state.medicalCard, name, value);
        },
        addReactionItem(state, { item, endpoint, }) {
            const items = [...state.medicalCard[endpoint]];
            item.new = true;
            items.push(item);
            state.medicalCard[endpoint] = items;
        },
        deleteReactionItem(state, { id, endpoint, }) {
            const items = [...state.medicalCard[endpoint]];
            state.medicalCard[endpoint] = items.filter((item) => item.id !== id);
        },
        setPatient(state, patient) {
            state.patient = patient;
        },
    },
    actions: {
        setUser({ commit }, { data }) {
            commit('setPropertyInStore', { name: 'id', value: data.id });
            console.log('ss');
            //       const { gender, birth_date } = this.$store.state.personalArea.medicalCard;
            // const { from_jlab } = this.$store.state.personalArea.patient;
            // const userMustSetPersonalData = from_jlab && (gender === null || birth_date === null);
            // if (
            //   userMustSetPersonalData &&
            //   this.$router.currentRoute.fullPath !== '/personal-area?activeTab=medical-card'
            // ) {
            //   this.$store.commit('personalArea/setUserMustSetPersonalData');
            //   this.$router.push('personal-area?activeTab=medical-card');
            // }
        },
        async init({ dispatch, state, commit }) {
            const error = await dispatch('getMedicalCardData');
            if (error?.errorData?.error_code === 'not_authenticated') {
                // router.push({ name: 'authPage' });
            }
            await dispatch('getPatient');
            // check userMustSetPersonalData
        },
        async getMedicalCardData({ commit, dispatch }) {
            const data = await $api.medcard.getMedCard();
            commit('setPropertyInStore', { name: 'medicalCard', value: data });
        },
        async getPatient({ commit, dispatch }) {
            const data = await $api.patient.getPatient();
            commit('setPropertyInStore', { name: 'patient', value: data });
        },
        async updateMedicalCardData({ dispatch, commit }, updateData) {
            const data = await $api.medcard.changeMedCard(updateData);
            commit('setPropertyInStore', { name: 'medicalCard', value: data });
        },
        async updatePatientData({ dispatch, commit }, updateData) {
            const data = await $api.patient.changePatient(updateData);
            commit('setPatientProperty', { name: 'patient', value: data });
            return data;
        },
        async addReactionItem({ dispatch, commit }, { endpoint, reactionData }) {
            const data = await $api.medcard.addReactionItem({
                endpoint,
                data: reactionData,
            });
            commit('addReactionItem', { item: data, endpoint });
        },
        async updateReactionsItem({ dispatch }, { id, endpoint, item }) {
            await $api.medcard.updateReactionItem({ endpoint, id, data: item });
        },
        async deleteReactionItem({ commit, dispatch }, { id, endpoint }) {
            await $api.medcard.deleteReactionItem({ endpoint, id });
            commit('deleteReactionItem', { id, endpoint });
        },
        async changeAvatar({ commit, dispatch }, fromData) {
            const data = await $api.patient.changePatient(fromData);
            commit('setPatientProperty', { name: 'avatar', value: data.avatar });
        },
    },
    getters: {
        hasGenderAndBirthday: (state) => {
            // console.log(state.patient.user_id, state.medicalCard.gender, state.medicalCard.birth_date);
            return (!!state.patient.user_id && !!state.medicalCard.gender && !!state.medicalCard.birth_date);
        },
    },
};
