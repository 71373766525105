<template lang="pug">
  q-btn.base-btn(
    flat
    :class="modificators" 
    :disabled="disabled"
    @click="$emit('click')"
    :type="href ? 'a' : 'button'" 
    :href="href"
    target="_blank")
    icon.base-btn__icon(
      :style="{marginRight: $slots.default ? '7px' : '0'}"
      v-if="icon" 
      :name="icon" 
      :width="iconSize" 
      :height="iconSize"
      :class="modificators" )
    .base-btn__content: slot
</template>

<script>
export default {
  name: 'BaseBtn',
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['dark', 'light', 'outline', 'outline2', 'square', 'transparent', 'error'].includes(value)
      },
      default: 'dark'
    },
    size: {
      type: String,
      validator: function (value) {
        return ['xs','sm', 'md'].includes(value)
      }
    },
    wide: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    icon: {
      type: String
    },
    href: {
      type: String,
      default: null
    },
    iconSize: {
      type: Number,
      default: 25
    },
  },
  computed: {
    modificators() {
      return {
        [`_${this.type}`] : this.type,
        [`_${this.size}`] : this.size,
        _wide : this.wide,
        }
    },
  }
}
</script>

<style lang="scss">
// TODO stylus?
.q-btn.base-btn {
  border-radius: 16px; 
  text-transform: none;
  font-size: 12px;
  line-height: 18px;
  font-weight: normal;
  flex-shrink: 0;
  transition-property: background, color;
  transition: .1s;

  @include media-down($breakpoint-md) {
    font-size: 13px;
  }

  &._xs {
    height: 35px;
  }

  &._sm {
    height: 42px;
  }

  &._md {
    height: 50px;
  }

  &._dark {
    background: $accent-color;
    border-color: $accent-color;
    color: white
  }

  &._error {
    background: $status-red;
    color: white
  }

  &._transparent {
    background: transparent;
    color: $black-02;
    svg {
      color: $accent-color
    }
    &:hover {
      background: transparent;
    }
  }

  &._light {
    background: $light-background;
    color: #A0A0B8;
    &:hover {
      @extend ._dark;
      .base-btn__icon {
        color: white;
        .colored-stroke {
          stroke: $accent-color
        }
        .colored-fill {
          fill: $accent-color
        }
      }
    }
  }

  &._outline {
    background: transparent;
    color: $grey-2;
    border: 1px solid #D4D1FD;
    &:hover {
      @extend ._dark;
      .base-btn__icon {
        color: white;
        .colored-stroke {
          stroke: $accent-color
        }
        .colored-fill {
          fill: $accent-color
        }
      }
    }
  }

  &._outline2 {
    background: transparent;
    color: $grey-2;
    border: 1px solid $grey-6;
    .base-btn__icon {
      color: $accent-color;
    }
    &:hover {
      @extend ._dark;
      .base-btn__icon {
        color: white;
        .colored-stroke {
          stroke: $accent-color
        }
        .colored-fill {
          fill: $accent-color
        }
      }
    }
  }

  &._square {
    background: transparent;
    color: $grey-2;
    border: 1px solid $grey-6;
    border-radius: 10px;
    &:hover {
      border: 1px solid $grey-5;
      .base-btn__icon {
        color: white;
        .colored-stroke {
          stroke: $accent-color
        }
        .colored-fill {
          fill: $accent-color
        }
      }
    }
  }

  // &._outline-accent {
  //   background: transparent;
  //   border: 1px solid $accent-color;
  //   color: $accent-color;
  // }
  
  &._wide {
    width: 100%;
  }
}

.base-btn__icon {
  transition-property: color;
  transition: .1s;

  &._light {
    color: $accent-color;
    .colored-stroke {
      stroke: white
    }
    .colored-fill {
      stroke: fill
    }
  }
  &._outline {
    color: $accent-color;
    .colored-stroke {
      stroke: $light-background
    }
    .colored-fill {
      fill: $light-background
    }
  }
  &._dark {
    color: white;
  }

}

.base-btn__content {
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>