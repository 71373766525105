import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let AuthLayout = class AuthLayout extends Vue {
    async mounted() {
        if (this.isUserLoggedIn)
            await this.$store.dispatch('personalArea/init');
    }
    get isUserLoggedIn() {
        return this.$store.getters['auth/isLoggedIn'];
    }
};
AuthLayout = __decorate([
    Component({})
], AuthLayout);
export default AuthLayout;
