<template lang="pug">
.menuItem(:to="link" :exact="exact" :is="link ? 'router-link' : 'div'")
  icon(:name="icon" :width="24" :height="24").menuItem__icon
  .menuItem__text {{text}}
  slot
</template>

<script>
export default {
  props: {
    link: String,
    icon: String,
    text: String,
    exact: Boolean,
  }
}
</script>

<style lang="scss">
.menuItem {
  width: 66px;
  height: 66px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  text-align: center;
  gap: 5px;
  transition: all 0.2s ease-in-out;
  position: relative;
  &.router-link-active {
    background: $violet-1;
    box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
  }
  &:hover{
    background: $violet-1;
    box-shadow: none;
  }

}

.menuItem__icon{
  transition: all 0.2s ease-in-out;
  .menuItem:hover &, .router-link-active &{
    transform: translateY(12px)
  }
}

.menuItem__text{
  transition: all 0.2s ease-in-out;
  font-size: 11px;
  line-height: 14px;
  .menuItem:hover &, .router-link-active &{
    opacity: 0;
  }
}
</style>