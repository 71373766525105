<template lang="pug">
div
  .cursor-area(tabindex="0" @focus="isShow = true" @blur="isShow = false" :class="{_big: big}" @click.capture.prevent.stop) 
    icon(size="16px" name="dots-icon")
  q-tooltip(:delay="500" :value="isShow" content-class="tooltip-actions" self="bottom right" anchor="top left" :offset="[-30, 0]")
    .tooltip-actions__item(v-for="action in actions" @click="$emit(action)")
      icon(width="12px" :name="allActions[action].icon" :color="allActions[action].colorIcon")
      span {{allActions[action].title}}
</template>

<script>
export default { 
  data(){ return { 
      isShow: false,
      allActions: { 
        edit: {title: 'Редактировать', icon: 'edit-icon', colorIcon: '#7C74E9'},
        delete: {title: 'Удалить', icon: 'delete-icon', colorIcon: '#FF7C7C'},
        comment: {title: 'Комментарий', icon: 'comment-icon-fill', colorIcon: '#7C74E9'},
        uploadAuto: {title: 'Загрузить файл анализа ', icon: 'edit-icon', colorIcon: '#FD7844'},
        uploadManual: {title: 'Загрузить анализ вручную', icon: 'delete-icon', colorIcon: '#FD7844'},
        otherDoc: {title: 'Другой документ', icon: 'delete-icon', colorIcon: '#FD7844'},
      }
    }
  },
  // TODO positions top left
  props: ['actions', 'position', 'big'],
}
</script>