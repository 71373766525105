import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { ILayout } from '@/interfaces/layout.interface';
var LayoutType = ILayout.LayoutType;
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import AddResearchModal from '@/components/modals/AddResearchModal.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import { userFirstVisit } from '@/utils/utils.ts';
let App = class App extends Vue {
    constructor() {
        super(...arguments);
        this.showResearch = false;
        this.isFileModalOpen = false;
        this.fileModalOpenData = {
            decipherAnalyzes: false,
            isAnalyzeDownload: true,
        };
        this.firstVisitFromSite = false;
    }
    async mounted() {
        const isAuthenticated = this.$store.getters['auth/isLoggedIn'];
        isAuthenticated && (await this.$store.dispatch('personalArea/init'));
        if (!isAuthenticated && window.location.pathname !== '/login-page') {
            this.$router.push({ name: 'loginPage' });
        }
        else {
            // checkUserRequiredData()
        }
        bus.$on(IAnalyzes.BusEvents.ADD_RESEARCH_OPEN, () => (this.showResearch = !this.showResearch));
        bus.$on(IAnalyzes.BusEvents.TOGGLE_DOCUMENT_MODAL, (data) => {
            this.isFileModalOpen = !this.isFileModalOpen;
            this.fileModalOpenData = data;
        });
        this.getCommonVariables();
    }
    async getCommonVariables() {
        const { dispatch } = this.$store;
        // TODO допилить
        dispatch('auth/getAllStaticVariables'),
            this.checkFirstVisitFromSite();
    }
    checkFirstVisitFromSite() {
        const srcQuery = new URL(location.href).searchParams.get('src');
        if (srcQuery === 'new') {
            this.firstVisitFromSite = userFirstVisit({ localStorageItem: 'firstVisitFromSite', id: true });
        }
    }
    toggleFileModal() {
        this.isFileModalOpen = false;
    }
    get layout() {
        return this.$route.meta.layout || LayoutType.DEFAULT;
    }
    destroyed() {
        bus.$off(IAnalyzes.BusEvents.ADD_RESEARCH_OPEN);
    }
};
App = __decorate([
    Component({
        components: {
            ConfirmModal,
            AddResearchModal,
            AddFileModal,
            MessageModal
        },
    })
], App);
export default App;
