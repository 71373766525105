import request from '@/utils/request.js';

export const deletePatient = async ({ reason }) => {
  const res = await request({
    method: 'delete',
    url: `patient/${reason}`,
  });
  return res;
};

export const changePatient = async (data) => {
  const res = await request({
    method: 'patch',
    url: `/patient/`,
    data,
  });
  return res.data;
};

export const getPatient = async (data) => {
  const res = await request({
    method: 'get',
    url: `/patient/`,
    data,
  });
  return res.data;
};
