export var IError;
(function (IError) {
    let errorActions;
    (function (errorActions) {
        errorActions["CLOSE"] = "close";
        errorActions["CLOSE_AND_RELOAD"] = "closeAndReload";
    })(errorActions = IError.errorActions || (IError.errorActions = {}));
    let ErrorCodes;
    (function (ErrorCodes) {
        ErrorCodes["TOKEN_NOT_VALID"] = "token_not_valid";
        ErrorCodes["AUTHENTICATION_FAILED"] = "authentication_failed";
    })(ErrorCodes = IError.ErrorCodes || (IError.ErrorCodes = {}));
})(IError || (IError = {}));
