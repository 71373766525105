import request from '@/utils/request.js';

export const getDocuments = async () => {
  const res = await request({
    method: 'get',
    url: `/documents/`,
  });
  return res.data;
};

export const getDocumentResults = async ({ id }) => {
  const res = await request({
    method: 'get',
    url: `/documents/${id}/results/`,
  });
  return res.data.results;
};

export const getDocument = async ({ id }) => {
  const res = await request({
    method: 'get',
    url: `/documents/${id}/`,
  });
  return res.data;
};

export const deleteDocument = async ({ id }) => {
  const res = await request({
    method: 'delete',
    url: `/documents/${id}/`,
  });
  return res;
};

export const createDocument = async (data) => {
  const res = await request({
    method: 'post',
    url: `/documents/`,
    data,
  });
  return res;
};

export const changeDocument = async (data) => {
  const res = await request({
    method: 'post',
    url: `/documents/${id}/`,
    data,
  });
  return res;
};

export const createFile = async (data) => {
  const formData = new FormData();
  data.fileList.forEach((file, index) => {
    formData.append(`file_${index}`, file);
  });
  // Чекнуть? вынести?

  const res = await request({
    method: 'post',
    url: `/documents/${data.id}/files/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const deleteFile = async ({ documentId, fileId }) => {
  const res = await request({
    method: 'delete',
    url: `/documents/${documentId}/files/${fileId}/`,
  });
  return res;
};
