import request from '@/utils/request.js';

export const forgotPassword = async (data) => {
  const res = await request({
    method: 'post',
    url: `/auth/forgot-password/`,
    data,
  });
  return res;
};

export const loginUser = async (data) => {
  const res = await request({
    method: 'post',
    url: `/auth/login/`,
    data,
  });
  return res.data;
};

export const passwordCheckCode = async (params) => {
  const res = await request({
    method: 'get',
    url: `/auth/check-code/`,
    params,
  });
  return res;
};

export const checkUser = async ({ patient_id, phone, email, reason }) => {
  const res = await request({
    method: 'get',
    url: `/auth/check/`,
    params: { patient_id, phone, email },
  });
  return res.data;
};

export const loginById = async ({ patient_id, fio, research_date }) => {
  const res = await request({
    method: 'post',
    url: `/auth/login-with-id/`,
    data: { patient_id, fio, research_date },
  });
  return res.data;
};

export const passwordReset = async (data) => {
  const res = await request({
    method: 'post',
    url: `/auth/reset-password/`,
    data,
  });
  return res;
};

export const sendCode = async (data = { debug, phone, email, reason }) => {
  const res = await request({
    method: 'post',
    url: `/auth/send-code/`,
    data,
  });
  return res;
};

export const confirmCode = async (data = { phone, email, code }, token) => {
  const headers = token ? { Authorization: `Bearer ${token}` } : null;
  const res = await request({
    method: 'post',
    url: `/auth/confirm/`,
    data,
    headers,
  });
  return res.data;
};

export const refreshToken = async ({ refresh }) => {
  const res = await request({
    method: 'post',
    url: `/auth/refresh-tokens/`,
    data: { refresh },
  });
  return res.data;
};

export const changeCabinet = async ({ user_id }) => {
  const res = await request({
    method: 'post',
    url: `/auth/refresh-tokens/`,
    data: { user_id },
  });
  return res.data;
};

export const authUser = async ({ authData, authType }) => {
  const res = await request({
    method: 'post',
    url: `/auth/${authType}/`,
    data: authData,
  });
  return res.data;
};

export const registerUser = async (data = { name, surname, phone, code }) => {
  const res = await request({
    method: 'post',
    url: `/auth/register/`,
    data,
  });
  return res.data;
};
