import router from './router';
import store from './store';
import Vue from 'vue';

export default function checkUserRequiredData() {
  const isOnline = window.navigator.onLine;
  if (isOnline && !!Vue.$cookies.get('refreshToken')) {
    const { gender, birth_date } = store.state.personalArea.medicalCard;
    const { from_jlab } = store.state.personalArea.patient;
    const userMustSetPersonalData = !from_jlab && (gender === null || birth_date === null);
    if (userMustSetPersonalData) {
      store.commit('personalArea/setUserMustSetPersonalData');
      router.push('personal-area?activeTab=medical-card');
    }
  }
}
