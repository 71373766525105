import request from '@/utils/request.js';

export const getOrders = async () => {
  const res = await request({
    method: 'get',
    url: `/dashboard/ordered-services/`,
  });
  return res.data.orders;
};

export const getOverview = async () => {
  const res = await request({
    method: 'get',
    url: `/dashboard/overview/`,
  });
  return res.data;
};

export const getDocuments = async () => {
  const res = await request({
    method: 'get',
    url: `/dashboard/documents/`,
  });
  return res.data.documents;
};

export const getResults = async () => {
  const res = await request({
    method: 'get',
    url: `/dashboard/results/`,
    params: {
      count: 8,
    },
  });
  return res.data.results;
};
