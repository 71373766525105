import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let PersonalAreaMixin = class PersonalAreaMixin extends Vue {
    get patient() {
        return this.$store.state.personalArea.patient;
    }
    get patientName() {
        const name = this.patient.name
            ? this.patient.name[0]?.toUpperCase() + this.patient.name.slice(1)?.toLowerCase()
            : ' - ';
        return name;
    }
};
PersonalAreaMixin = __decorate([
    Component({})
], PersonalAreaMixin);
export default PersonalAreaMixin;
