<template lang="pug">
MessageModal(
  :title="title"
  :value="value" 
  @close="close"
  rowButtons)
  template(#body): slot
  template(#buttons)
    BaseBtn(
      @click="$emit('confirm')" 
      :iconSize="iconSize" 
      :icon="iconButton" 
      :type="typeButton" 
      size="sm") {{textButton}}
    BaseBtn(
      @click="close" 
      size="sm" 
      type="transparent") {{textClose}}
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal},
  props: {
    value: {
      default: false
    },
    textClose: {
      type: String,
      default: 'Отмена'
    },
    textButton: {
      type: String,
      default: 'Ок'
    },
    typeButton: {
      type: String,
      default: 'dark'
    },
    iconButton: {
      type: String,
    },
    iconSize: {
      type: Number,
    },
    title: {
      type: String,
      default: 'Подтверждение'
    },
  },
  methods: {
    close(){
      this.$emit('close')
      this.$emit('input', false); 
    }
  }
}
</script>

<style lang="scss">
.message-modal._confirmation {
    padding: 30px;
}
  // .confirmation-modal {
  //   background: white;
  //   padding: 30px 40px;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 20px;
  //   align-items: center;
  //   text-align: center;
  // }
  .confirmation-modal__buttons {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
</style>