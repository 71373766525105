import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
;
// TODO remove all mixin
let BaseFormMixins = class BaseFormMixins extends Vue {
    constructor() {
        super(...arguments);
        this.onlyLetters = /[a-zA-ZА-Яа-я ]*/;
        this.onlyLettersAndHyphen = /[a-zA-ZА-Яа-я -]*/;
        this.onlyNumber = /[0-9]*/;
        this.phonePattern = /^\d[\d\(\)\ -]{6,14}\d$/;
        this.emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
        this.minLength = 1;
        this.maxLength = 20;
        this.passwordPattern = /^(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;
        this.inputRules = {
            maxMinlength: (val) => (val.length > this.minLength && val.length <= this.maxLength) ||
                `мин ${this.minLength + 1}, макс ${this.maxLength} символов`,
            required: (val) => val?.toString()?.length || 'Обязательно к заполнению',
            validEmail: (val) => this.emailPattern.test(val) || 'Неверный адрес электронной почты',
            validPhone: (val) => this.phonePattern.test(val) || 'Неверный номер телефона',
            validPassword: (val) => this.passwordPattern.test(val) || `Пароль должен содержать: буквы и цифры, заглавную букву, минимум 8 символов`,
        };
    }
    checkError(ref) {
        if (ref)
            return ref.hasError;
        else
            return false;
    }
    showSelectOptions(ref) {
        ref.showPopup();
    }
    checkInputValueByRegExp(regexp, value) {
        return value.match(regexp)[0];
    }
};
BaseFormMixins = __decorate([
    Component({})
], BaseFormMixins);
export default BaseFormMixins;
