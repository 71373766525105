import { render, staticRenderFns } from "./DialogModal.vue?vue&type=template&id=4f73628c&scoped=true&"
import script from "./DialogModal.vue?vue&type=script&lang=ts&"
export * from "./DialogModal.vue?vue&type=script&lang=ts&"
import style0 from "./DialogModal.vue?vue&type=style&index=0&id=4f73628c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f73628c",
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog});qInstall(component, 'directives', {ClosePopup});
