export const rounding = (value, symbols = 3) => {
  if (value === null) return null;
  return parseFloat(value.toFixed(symbols));
};

export const getRangeText = (ranges, coeff, symbols = 4, type = 'math') => {
  if (ranges) {
    let rangeText = '';
    let { min, max } = ranges;
    const minExist = typeof min === 'number';
    const maxExist = typeof max === 'number';
    console.log(min, max);
    const signLess = type === 'word' ? 'до' : '<';
    const signMore = type === 'word' ? 'от' : '>';

    min = rounding(min * coeff, symbols);
    max = rounding(max * coeff, symbols);

    if (minExist && maxExist) rangeText = `${min} - ${max}`;
    else if (!maxExist) rangeText = `${signMore} ${min}`;
    else if (!minExist) rangeText = `${signLess} ${max}`;
    else rangeText = '-';

    return rangeText;
  } else return '';
};
