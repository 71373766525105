<template lang="pug">
.analyze-item__wrapper
  CheckboxInput(v-if="isCompareMode" :value="isChecked" @input="compareChange(analyze.biomarker_group_id)" :class="{invisible: !analyze.biomarker_group_id}")
  //- .analyze-item(:is="tagElement" :to="linkRouteParams" :class="modificators" @click.native="handleClick")
  .analyze-item(:is="tagElement" :to="linkRouteParams" :class="modificators" @click="handleClickOnClosedLink")
    .analyze-item__content._main(:class="modificators")
      .analyze-item__title(v-if="type !== 'laconic'")
        .analyze-item__title-text(:title="analyze.name || analyze.biomarker") {{ analyze.name || analyze.biomarker }}
        .analyze-item__biomaterial(v-if="analyze.biomaterial") {{ analyze.biomaterial }}
      .analyze-item__value(:class="modificators")
        .analyze-item__value-text(:title="value" :class="valueTextClassObject") {{ value }}
        .analyze-item__num(v-if="numValue" :class="modificators") {{ numValue }}
        .analyze-item__rel(v-if="analyze.rel_value" :class="modificators") {{ analyze.rel_value }}
        .analyze-item__diff(v-if="!isEnum && showPrevResult && analyze.prev_result")
          .analyze-item__diff-prev {{Number.isInteger(analyze.prev_result.value) ? rounding(analyze.prev_result.value) : analyze.prev_result.value}}
          .analyze-item__diff-current(:class="{ _decrease: resultDifference <= 0}")
            icon(name="difference-arrow" width="10px" )
            span {{resultDifference}}
          .analyze-tooltip
            icon.web(name="question2")
            q-tooltip(anchor="bottom right" self="bottom left" :offset="[10, 10]")
              span  Ваш предыдущий результат и разница между ним и текущим.
      //- TODO to component
      template(v-if="isShowComment")
        .analyze-item__comment(v-if="analyze.comment" @click.prevent="$emit('showComment', analyze.comment)")
          icon(name="comment-icon-fill" height="10")
          .analyze-item__comment-text {{analyze.comment}}
        .analyze-item__comment(v-else @click.prevent.stop="$emit('addComment')")
          icon(name="comment-icon" height="10")
          .analyze-item__comment-text Добавить комментарий
    .analyze-item__content._aside(:class="modificators")
      .analyze-item__unit(:class="modificators" v-if="!isEnum") {{ detailPage ? analyze.unit : analyze.last_unit }}
      .analyze-items__range(:class="modificators" v-if="!isEnum") {{ rangesText }}
    //- .analyze-item__pathology(v-else="" :class="modificators")
    //-   span {{this.value.is_pathology ?  'Является патологией' : 'Не является патологией'}}
    //-   icon(name="doc-question-icon" width="14px" color="#E9E8FF")
    .analyze-item__content._footer
      .analyze-item__lab(v-if="!hideLab" :class="modificators") {{ analyze.laboratory }}
      .analyze-item__date.text-grey(:class="modificators") {{ dataText }}
    .analyze-item__content._actions
      .analyze-item__actions(v-if="isShowActions") 
        .cursor-area(@click.prevent.stop="$emit('edit')"): icon(width="12px" name="edit-icon")
        .cursor-area(@click.prevent.stop="$emit('delete')"): icon(width="12px" name="delete-icon")
      TooltipActions.analyze-item__more-actions(
        v-if="isShowActions"
        :big="true"
        :actions="['edit', 'delete', 'comment']"
        @edit="$emit('edit')" 
        @comment="$emit('addComment')" 
        @delete="$emit('delete')")
      BaseBtn(v-else-if="type === 'laconic'" type="square" icon="next-icon4" :iconSize="10" @click="goToOrder(analyze.ordered_analyze_service_id)").analyze-item__go-order Перейти в заказ
      button.analyze-item__btn( 
        v-if="type === 'link'"): span.analyze-item__btn-icon: icon(name="next-icon")
</template>

<script>
import TooltipActions from '@/components/UI/tooltip/TooltipActions.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import {getRangeText, rounding} from '@/utils/count-helpers.js';

export default {
  components: {
    TooltipActions,
    CheckboxInput,
  },
  data() {
    return {
      actions: null,
    };
  },
  props: {
    analyze: { 
      type: Object,
    },
    type: {
      type: String,
      default: 'card', 
      validator: function (value) {
        return ['card', 'link', 'laconic'].includes(value)
      }
    },
    showPrevResult: {
      type: Boolean,
      default: false,
    },
    hideLab: {
      type: Boolean,
      default: false,
    }, 
    detailPage: {
      type: Boolean,
      default: false,
    }, 
  },
  computed: {
    value() {
      if (this.isEnum) {
        const valuesArray = this.analyze.biomarker_enum_values || this.analyze.biomarker_unique_enum_values
        return valuesArray.find((item) => item.id === this.analyze?.value)?.value;
      } else {

        let result = rounding(this.analyze?.value);
        if (this.analyze.power_value) {
          result += `^${this.analyze.power_value}`;
        }
        return result;
      }
    },

    numValue() {
      const { num_value, power_value } = this.analyze;
      if (num_value && power_value) {
        return `${num_value}^${power_value}`;
      } else if (num_value) {
        return num_value;
      } else return '';
    },

    isEnum() {
      return this.analyze.type === 'enum';
    },

    resultDifference(){
      return rounding(this.analyze?.value - this.analyze.prev_result.value)
    },

    rangesText() {
      return getRangeText(this.analyze.ranges, 1, 2, 'word')
    },

    dataText() {
      return this.$date(new Date(this.analyze.date), 'dd MMMM yyyy');
    },
    
    isShowComment(){
      return this.type === 'laconic' && !this.analyze.from_jlab;
    },

    isShowActions(){
      return (this.type === 'card' || (this.type === 'laconic' &&  !this.analyze.from_jlab)) ;
    },

    modificators() {
      return { 
        _enum: this.isEnum, 
        [`_${this.type}`]: this.type,
        ['_hide-lab']: this.hideLab,
        _compare: this.isCompareMode,
        _checked: this.isChecked,
        _isnt_detailed_page: !this.analyze?.is_detailed_page && this.type !== 'laconic'
      };
    },

    valueTextClassObject() {
      const classObject = {...this.modificators}
      const {range_zone} = this.analyze
      //  if (this.isEnum) {
      //   classObject['text-red'] = this.analyze?.is_pathology === true
      //   classObject['text-green'] = this.analyze?.is_pathology === false
      //   classObject['text-primary'] = this.analyze?.is_pathology === null
      //   return classObject
      // } else {
        classObject['text-green'] = range_zone === 'in_norm'
        classObject['text-yellow'] = range_zone === 'border_zone'
        classObject['text-red'] = range_zone === 'out_of_norm'
        classObject['text-primary'] = range_zone === null
        return classObject
      // } 
    },

    // TODO replace store by props
    isCompareMode() {
      return this.$store.state.analyzes.compareMode;
    },
    // TODO replace store by props
    isChecked() {
      return this.$store.state.analyzes.comparingItems.includes(this.analyze.biomarker_group_id);
    },

    linkRouteParams() {
      const id = this.analyze.biomarker_group_id || this.analyze.biomarker_id
      return { name: 'AnalyzePage', params: { id } }
    },

    closedLink(){
      return this.linkRouteParams?.params.id === this.$route.params.id && this.$route.name === 'AnalyzePage'
    },

    tagElement(){
      if (this.closedLink) return 'div'
      const routerLinkCond = (this.type === 'link' || this.type === 'card') && this.analyze?.is_detailed_page
      return routerLinkCond ? 'router-link' : 'div'
    }
    
  },
  methods: {
    handleClickOnClosedLink(){
      if (this.closedLink) this.$router.go()
    },
    rounding,
    goToAnalyzePage(blank) {
      const id = this.analyze.biomarker_group_id || this.analyze.biomarker_id
      if (!id) return
      if (this.type !== 'laconic') {
        const routeParams = { name: 'AnalyzePage', params: { id } }
        if(blank){
          const routeData = this.$router.resolve(routeParams);
          window.open(routeData.href, '_blank');
        } else {
          this.$router.push(routeParams);        
        }
      }
    },

    goToOrder(id){
      this.$router.push({ name: 'orderPage', params: { id } });        
    },

    // TODO replace store by props
    compareChange(biomarker_group_id) {
      this.$store.commit('analyzes/setComparingItems', biomarker_group_id);
    }
  },
};

</script>

<style lang="scss">
.analyze-item__wrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 100%;
}

.analyze-item {
  flex-grow: 1;
  padding: 14px 28px;
  background: white;
  color: #2d2d2d;
  border-radius: 12px;
  display: grid;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: $table-grid-columns;
  position: relative;
  text-decoration: none;

  @include media-down($breakpoint-md) {
    padding: 11px 13px;
    box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
    border-radius: 20px;
    align-items: flex-start;
    gap: 5px 20px;
  }

  &:hover,
  &:focus {
  
    box-shadow: 0px 4px 15px rgba(#16142C, 0.06);
    // .analyze-item__btn {
    //   opacity: 1;
    // }
  }
  &._link, &._card {
    cursor: pointer;
  }
  &._compare:not(._checked) {
    opacity: 0.4;
  }
  &._enum {

  }
  &._laconic {
    grid-template-columns: $table-grid-columns_laconic ;
    grid-auto-flow: dense;
  }
  &._laconic._enum {
    grid-template-columns: $table-grid-columns_laconic_enum ;
  }
  &._hide-lab {
    grid-template-columns: $table-grid-columns_hide-lab;
  }
  @include media-down($breakpoint-md) {
    grid-template-columns: 1.8fr 1.1fr !important;
  }
  &._isnt_detailed_page {
    border: 1px solid #E9E8FF;
    background: transparent;
    cursor: not-allowed;
  }
}

.analyze-item__content {
  display: contents;
  @include media-down($breakpoint-md) {
    display: flex;
    &._main {
      grid-area: 1 / 1;
      flex-direction: column;
      gap: 5px;
      &._enum{
        grid-column: 1 / 3;
      }
      &._laconic{
        grid-column: 1 / 3;
      }
    }
    &._aside {
      grid-area: 1 / 2;
      flex-direction: column;
      gap: 4px;
      align-items: flex-end;
      &._laconic{

        
      }
    }
    &._footer {
      grid-area: 2 / 1;
      flex-direction: row;
      gap: 15px;
      align-items: center;
      height: 100%;
    }
    &._actions {
      grid-area: 2 / 2;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.analyze-item__title {
  @include media-up($breakpoint-md) {
    padding-right: 20px;
  }
  @include media-down($breakpoint-md) {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}

.analyze-item__title-text {
  @include media-up($breakpoint-md) {
    @include trim-text(2);
  }
  @include media-down($breakpoint-md) {
    font-size: 14px;
    line-height: 18px;
  }
}

.analyze-item__value {
  &._enum {
    grid-column: span 2;
  }
  &._laconic {
    grid-column: 1;
    grid-row: 1
  }
}

.analyze-item__value-text {
  @include media-up($breakpoint-md) {
    @include trim-text(1);
  }
  @include media-down($breakpoint-md) {
    font-size: 16px;
    line-height: 21px;
  }
  &._enum {
  }
}

.analyze-item__biomaterial {
  font-size: 12px;
  line-height: 15px;
  color: $accent-color;
  @include media-down($breakpoint-md) {
    order: -1;
  }
}

.analyze-item__unit {
  @include media-down($breakpoint-md) {
    font-size: 12px;
    line-height: 15px;
    color: $grey-3;
  }
  @include media-up($breakpoint-md) {
    grid-row: 1;
    grid-column: 3;

    &._laconic {
      grid-column: 2
    }
  }
}

.analyze-items__range {
  @include media-down($breakpoint-md) {
    font-size: 12px;
    line-height: 15px;
    color: $grey-3;
    order: -1;
    ._laconic &{
      display: none
    }
  }
    @include media-up($breakpoint-md) {
    grid-row: 1;
    grid-column: 4;
    &._laconic {
      grid-column: 3
    }
    
  }
}

.analyze-item__rel {
  color: #a0a0b8;
  display: inline;
  
  &._enum {

  }
}

  .analyze-item__num {
    display: inline;
    &._enum {

    }
    & + .analyze-item__rel {
      margin-left: 10px;
    }
  }

  .analyze-item__diff {
    display: inline;
    font-size: 12px;
    line-height: 15px; 
    color: #A0A0B8;
    display: flex;
    gap: 15px;
    align-items: center;
  }

  .analyze-item__diff-current {
    display: flex;
    gap: 4px;
    flex-wrap: nowrap;
    &._decrease {
      .fa-icon {
        transform: rotate(180deg)
      }
    }
  }

.analyze-item__date {
  @include media-down($breakpoint-md) {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $black-04;
    order: -1;
  }
  @include media-up($breakpoint-md) {
    grid-row: 1;
    grid-column: 5;
    &._link {
      grid-column: 6;
    }
    &._laconic._enum {
      grid-column: auto;
    }
  }
}

.analyze-item__lab {
  @include media-down($breakpoint-md) {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: $black-01;
  }
  @include media-up($breakpoint-md) {
    grid-row: 1;
    grid-column: 4;
    &._link {
      grid-column: 5;
    }
    &._card {
      grid-column: 6;
    }
    &._laconic._enum {
      grid-column: auto;
    }
  }
  @include trim-text(1)
}
.analyze-item__actions {
  display: flex;
  gap: 20px;
  color: #dfdfeb;
  grid-column: -2;
  @include media-down($breakpoint-md) {
    display: none;
  }
}

.analyze-item__pathology {
  // TODO кандидат на удаление
  display: flex;
  align-items: center;
  gap: 15px;
  color: #a0a0b8;
  &._enum {
    @include media-down($breakpoint-md) {
      display: none;
    }
  }
}

.analyze-item__comment {
  grid-column: -3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  gap: 8px;
  border: 1px solid #F4F4F4;
  border-radius: 20px;
  padding: 0 12px;
  margin-right: 25px;
  cursor: pointer;
  @include media-down($breakpoint-md) {
    margin: 8px 0;
  }
}

.analyze-item__comment-text {
  @include trim-text(1);
}

.analyze-item__more-actions {
  display: none;
  @include media-down($breakpoint-md) {
    display: block;
  }
}

.analyze-item__btn {
  cursor: pointer;
  position: absolute;
  right: -15px;
  display: flex;
  padding: 11px 13px;
  background-color: $accent-color;
  border: none;
  border-radius: 12px;
  outline: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  @include media-down($breakpoint-md) {
    position: relative;
    right: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 1px solid $light-stroke;
    opacity: 1;
  }
  @at-root .analyze-item._isnt_detailed_page & {
    display: none;
  }
}

.analyze-item__btn-icon {
  display: flex;
  align-items: center;
  color: $light-white;
  transform: rotate(180deg);
  @include media-down($breakpoint-md) {
    color: $accent-color;
  }
  svg {
    width: 4px;
    height: 8px;
  }
}

.analyze-item__go-order {
  margin-left: auto;
  // grid-column: 6/8;
  grid-column: 4/-1;
  grid-row: 1;
}

</style>