import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { IMenu } from '@/interfaces/layout.interface';
var MAIN_MENU = IMenu.MAIN_MENU;
import { IRouter } from '@/interfaces/router.interface';
import PopupButton from '@/components/PopupButton.vue';
import MenuItem from './MenuItem.vue';
import BaseCounter from '@/components/UI/BaseCounter.vue';
var ROUTE_NAME = IRouter.ROUTE_NAME;
let Aside = class Aside extends Vue {
    constructor() {
        super(...arguments);
        this.routeName = ROUTE_NAME;
        this.menu = MAIN_MENU;
        this.isOpenHelpModal = false;
    }
    openChatra() {
        const { patient_id, name, surname, patronymic, phone, email } = this.$store.state.personalArea.patient;
        let userData = '';
        if (patient_id) {
            userData = `ID лабстори: ${patient_id}`;
        }
        if (surname || name || patronymic) {
            userData = `${userData}\nФИО: ${surname} ${name} ${patronymic}`;
        }
        if (email) {
            userData = `${userData}\nE-mail: ${email}`;
        }
        if (phone) {
            userData = `${userData}\nТелефон: ${phone}`;
        }
        window.Chatra.updateIntegrationData({
            notes: userData
        });
        window.Chatra.show();
        window.Chatra.openChat();
        this.isOpenHelpModal = false;
    }
    ;
    mounted() {
        this.setBurgerModalCoords();
    }
    setBurgerModalCoords() {
        const coordOfBurger = this.$refs.burger.$el.getBoundingClientRect();
        const clientHeight = document.documentElement.clientHeight;
        const fromBottomToBurger = clientHeight - coordOfBurger.y - 80;
        document.documentElement.style.setProperty('--fromBottomToBurger', `${fromBottomToBurger}px`);
    }
};
Aside = __decorate([
    Component({ components: { PopupButton, MenuItem, BaseCounter } })
], Aside);
export default Aside;
