import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import DialogModal from '@/components/modals/DialogModal.vue';
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
import ConfirmationModal from '@/components/modals_new/templates/ConfirmationModal.vue';
import InputDate from '@/components/InputDate.vue';
import FilePicker from '@/components/modals/addFileModal/FilePicker.vue';
import PreviewImg from '@/components/modals/addFileModal/PreviewImg.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import FormInput from '@/components/UI/inputs/FormInput.vue';
import MainSelect from '@/components/UI/MainSelect.vue';
import { format } from 'date-fns';
import { serverDateFormat } from '@/interfaces/api.interface';
import { pluralizeText } from '@/utils/utils';
import { showClientError } from '@/utils/showNotify.js';
let AddFileModal = class AddFileModal extends Vue {
    constructor() {
        super(...arguments);
        this.showTooltip = false;
        this.isGoBackModalOpen = false;
        this.isDeleteFileModalOpen = false;
        this.modalVisibleType = 1;
        this.date = this.$date(new Date(), 'yyyy/MM/dd');
        this.isCheckboxValue = false;
        this.selectedType = '';
        this.selectedLab = '';
        this.fileList = [];
        this.documentName = '';
        this.isBtnDisabled = false;
        this.comment = '';
        this.resultDoc = null;
        this.isOpenDecipherModal = false;
        this.isOpenDocCreatedModal = false;
        this.isOpenConfirmClose = false;
    }
    get documentTypes() {
        const allDocTypes = this.$store.state.staticVariables?.staticVariables?.document_types;
        if (allDocTypes) {
            const docTypesWithoutAnalyzeType = allDocTypes.filter((item) => item.value !== 1);
            return docTypesWithoutAnalyzeType;
        }
        else
            return [];
    }
    get laboratoriesList() {
        if (this.fileModalType === 'document') {
            const labsArr = [...this.$store.state.staticVariables.labs];
            const otherLab = labsArr.pop();
            labsArr.unshift(otherLab);
            return labsArr;
        }
        else {
            return this.$store.state.staticVariables.labs;
        }
    }
    get pluralizeText() {
        return pluralizeText;
    }
    fileListChanged() {
        this.fileList.length ? (this.modalVisibleType = 2) : (this.modalVisibleType = 1);
    }
    function(val) {
        if (this.isAnalyzeDownload) {
            this.selectedType = val.find((el) => el.value === 1) || 1;
        }
    }
    // TODO shit
    updated() {
        if (this.selectedType?.value !== 1 && this.fileModalType === 'analyze') {
            this.selectedType = {
                description: "Анализ",
                value: 1
            };
        }
    }
    addFiles(files) {
        [...files].forEach((file) => {
            this.validateFile(file)
                ? this.fileList.push(file)
                : this.$store.dispatch('error/showErrorNotice', { message: 'Неверный формат файла' }, { root: true });
        });
    }
    validateFile(file) {
        return file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf';
    }
    clickDeleteIcon(index) {
        this.deletedFileIndex = index;
        this.toggleDeleteFileModal(true);
    }
    deleteFile() {
        this.fileList.splice(this.deletedFileIndex, 1);
        this.toggleDeleteFileModal(false);
    }
    changeDate(value) {
        this.date = value;
    }
    toggleGoBackModal(val) {
        this.isGoBackModalOpen = val;
    }
    toggleDeleteFileModal(val) {
        this.isDeleteFileModalOpen = val;
    }
    goBack() {
        this.toggleGoBackModal(false);
        this.fileList = [];
    }
    selectType(value) {
        this.selectedType = value;
    }
    selectLab(value) {
        this.selectedLab = value;
    }
    clearData() {
        this.selectedType = '';
        this.isCheckboxValue = false;
        this.fileList = [];
        this.date = '';
        this.documentName = '';
        this.comment = '';
    }
    validateFields() {
        if (!this.documentName) {
            showClientError('Введите название документа');
            return false;
        }
        if (!this.selectedLab) {
            showClientError('Выберите лабораторию');
            return false;
        }
        if (!this.selectedType) {
            showClientError('Выберите тип исследования');
            return false;
        }
        if (this.selectedType.value === 1 && this.isCheckboxValue && !this.date) {
            showClientError('Выберите дату исследования');
            return false;
        }
        const documentNameInput = this.$refs.documentNameInput;
        if (!documentNameInput.validate()) {
            return false;
        }
        return true;
    }
    async loadDocument() {
        if (this.isAnalyzeDownload) {
            this.isCheckboxValue = true;
            this.selectedType = (this.documentTypes?.find((el) => el.value === 1)) || 1;
        }
        const formIsValid = this.validateFields();
        if (!formIsValid) {
            return;
        }
        this.isBtnDisabled = true;
        const payload = {
            name: this.documentName,
            laboratory_id: this.selectedLab ? this.selectedLab.id : null,
            date: format(this.date ? new Date(this.date) : new Date(), serverDateFormat),
            type_doc: this.selectedType.value,
            allow_processing: this.selectedType.value === 1 ? this.isCheckboxValue : false,
            fileList: this.fileList,
            сomment: this.comment
        };
        this.resultDoc = await this.$store.dispatch('storage/createDocument', payload);
        this.isBtnDisabled = false;
        if (this.isCheckboxValue) {
            this.$metrika.reachGoal('userUploadWithDecipher');
            this.isOpenDecipherModal = true;
        }
        else {
            this.$metrika.reachGoal('userUploadWithoutDecipher');
            this.isOpenDocCreatedModal = true;
        }
    }
    goToDocPage() {
        this.$router.push({ name: 'singleDocumentPage', params: { id: this.resultDoc.id } });
        this.closeModal();
    }
    beforeHide() {
        if (this.formIsNotClean()) {
            this.isOpenConfirmClose = true;
        }
        else {
            this.closeModal();
        }
    }
    formIsNotClean() {
        return this.fileList[0] || this.documentName || this.comment || this.isCheckboxValue;
    }
    closeModal() {
        this.isOpenConfirmClose = false;
        this.clearData();
        return false;
    }
};
__decorate([
    Prop({ default: 'document' })
], AddFileModal.prototype, "fileModalType", void 0);
__decorate([
    Prop({ required: true })
], AddFileModal.prototype, "isFileModalOpen", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], AddFileModal.prototype, "decipherAnalyzes", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], AddFileModal.prototype, "isAnalyzeDownload", void 0);
__decorate([
    Watch('fileList')
], AddFileModal.prototype, "fileListChanged", null);
__decorate([
    Watch('documentTypes', { deep: true })
], AddFileModal.prototype, "function", null);
__decorate([
    Emit('close-modal')
], AddFileModal.prototype, "closeModal", null);
AddFileModal = __decorate([
    Component({
        components: { ConfirmationModal, MainSelect, BackBtn, MainBtn, CloseBtn, PreviewImg, FilePicker, InputDate, DialogModal, FormInput, MessageModal },
    })
], AddFileModal);
export default AddFileModal;
