import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let AnalyzesMixin = class AnalyzesMixin extends Vue {
    getRanges(ranges) {
        if (!ranges)
            return '';
        return ranges?.min !== null && ranges?.max !== null
            ? `${ranges.min} - ${ranges.max}`
            : ranges.min !== null
                ? `${ranges.min} >`
                : `< ${ranges.max}`;
    }
};
AnalyzesMixin = __decorate([
    Component({})
], AnalyzesMixin);
export default AnalyzesMixin;
