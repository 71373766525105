import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { IMenu } from '@/interfaces/layout.interface';
var MAIN_MENU = IMenu.MAIN_MENU;
import MenuItem from './MenuItem.vue';
import PopupButton from '@/components/PopupButton.vue';
import BaseCounter from '@/components/UI/BaseCounter.vue';
let Footer = class Footer extends Vue {
    constructor() {
        super(...arguments);
        this.menu = MAIN_MENU;
    }
};
Footer = __decorate([
    Component({ components: { MenuItem, PopupButton, BaseCounter } })
], Footer);
export default Footer;
