<template>
  <q-dialog :maximized="maximized" transition-show="fade" transition-hide="fade" :value="value" @hide="$emit('input')">
    <div class="manual-addition">
      <BackBtn v-close-popup class="back-btn lt-md"/>
      <div class="manual-addition__input-group" :class="{'_focus': focus}">
        <div class="manual-addition__title">Добавить анализ</div>
        <SearchInput
          placeholder="Начните вводить ваше название"
          v-model="query"
          @focus="focus = true" 
          @blur="focus = false" 
          :focus="focus"/>
        <div class="manual-addition__list scrollable" :class="{'_focus': focus}">
          <div v-if="biomarkers.length !== 0" class="manual-addition__attention">Обратите внимание, что наши названия могут немного отличаться.<br> Другие возможные варианты мы выводим под основным.</div>
          <BiomarkerItem 
            v-for="(item, index) in biomarkers" 
            v-bind="item"
            :query="query"
            :key="index" 
            @click.native="openAnalyzeForm(item)"  />
          <infinite-loading v-if="biomarkers.length !== 0" @infinite="loadNextPage">
              <LoadingSpinner slot="spinner" text=""/>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
          </infinite-loading>
          <LoadingSpinner v-if="loading" text="Идет поиск..." class="absolute-center"/>
          <span v-if="notFound" class="absolute-center text-primary" >Не найдено</span>
        </div>
      </div>
      <div v-if="addedAnalyzes.length === 0" class="manual-addition__tip">Например, вы хотите найти Тиреотропный гормон. <br/>Начните вводить «тиреотроп» и мы найдем все похожие анализы.</div>
      <div v-else class="manual-addition__added-analyzes">
        <AnalyzeItem 
          v-for="item in addedAnalyzes"
          :analyze="item"
          :key="item.id"
          @edit="openAnalyzeForm(item)"
          @delete="showDeleteModal(item)"/>        
      </div>
      <div class="manual-addition__close" @click="$emit('input')">
        <icon name="close-icon" height="8px" />
      </div>
    </div> 
    <q-dialog content-class="_scroll-in-window"  transition-show="fade" transition-hide="fade" v-model="isOpenAnalyzeForm">
      <!-- TODO V-IF? -->
      <!-- TODO Combine to one component ?-->
      <AnalyzeForm 
        :formProp="formProp"
        :documentId="documentId"
        @close="closeAnalyzeForm($event)" 
        @add="updateAddedAnalyze($event)"
      />
    </q-dialog>
    <ConfirmationModal
      v-model="isShowConfirmationModal"
      @confirm="deleteAddedAnalyze">
      Вы точно хотите удалить <br>добавленный анализ?
    </ConfirmationModal>
  </q-dialog>
</template>

<script>
import SearchInput from '@/components/UI/inputs/SearchInput.vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import BiomarkerItem from '@/components/analyzes/BiomarkerItem.vue';
import AnalyzeForm from '@/components/modals_new/complex/AnalyzeForm.vue';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import ConfirmationModal from '@/components/modals_new/templates/ConfirmationModal.vue';

import InfiniteLoading from 'vue-infinite-loading';

import { getBiomarkerGroups } from '@/api/analyzes.js';
import { paginationRequest } from '@/utils/utils';


export default {
  components: {
    SearchInput, 
    BiomarkerItem, 
    AnalyzeForm,
    LoadingSpinner,
    AnalyzeItem, 
    BackBtn, 
    ConfirmationModal,
    InfiniteLoading
  },
  data() {
    return { 
      focus: false,
      loading: false,
      query: '',
      biomarkers: [],
      addedAnalyzes: [],
      formProp: null, // biomarker (if new); or analyze (if edit) // привести к одному виду?
      isOpenAnalyzeForm: false,

      isShowConfirmationModal: false,
      dataConfirmationModal: null,
    }
  },
  computed: {
    notFound() {
      return this.query.length !== 0 && !this.loading && this.biomarkers.length === 0 
    }
  },
  watch: {
    async query(newValue){
      this.loading = true;
      this.biomarkers = []
      this.biomarkers = await getBiomarkerGroups({ query: newValue });
      this.loading = false;
    },
  },
  props: {
    value: {
      default: false, 
      type: Boolean
    },
    documentId: {
      type: Number
    },
    maximized: {
      type: Boolean,
      default: false, 
    }
  },
  methods: {
    openAnalyzeForm(formProp){
      this.formProp = formProp
      this.isOpenAnalyzeForm = true;
    },
    closeAnalyzeForm(status){
      this.formProp = null
      this.isOpenAnalyzeForm = false;
      if (status === 'success') {
        this.query = '';
        this.focus = false
      }
    },
    updateAddedAnalyze({data,isNew}){
      console.log(data,isNew)
      if (isNew) {
        this.addedAnalyzes.push(data)
      }
      else {
        const editableItemIndex = this.addedAnalyzes.findIndex(item => item.id === data.id)
        this.addedAnalyzes.splice(editableItemIndex, 1, data);
      }
    },

    showDeleteModal(data){
      this.isShowConfirmationModal = true;
      this.dataConfirmationModal = data
    },

    async deleteAddedAnalyze() {
      const res = await this.$store.dispatch('analyzes/deleteBiomarker', this.dataConfirmationModal.id )
      if (res?.status === 200) {
        const editableItemIndex = this.addedAnalyzes.findIndex(item => item.id === this.dataConfirmationModal.id)
        this.addedAnalyzes.splice(editableItemIndex, 1);
      }
      this.isShowConfirmationModal = false
    },

    async loadNextPage($state){
      const params = { 
        query: this.query, 
        offset: this.biomarkers.length
      }
      paginationRequest({request: getBiomarkerGroups, params, targetArray: this.biomarkers, $state })
    }
  },
}
</script>

<style lang="scss">
  .manual-addition {
    max-width: 100%!important;
    width: 100%;
    height: 100%;
    padding: 60px;
    background: $light-background;
    border-radius: 16px!important;
    position: relative;
    @include media-down($breakpoint-md) {
      padding: 30px 20px;
    }
  }

  .manual-addition__input-group {
    position: relative;
    transition: margin .2s ease-out;
    will-change: margin;
    &._focus {
      margin: 0 20px;
    }
  }

  .manual-addition__title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 12px;
    @include media-down($breakpoint-md) {
      margin-top: 40px
    }
  }

  .manual-addition__tip {
    margin-top: 16px;
    font-size: 14px;
    line-height: 18px;
    color: #A0A0B8;
  }

  .manual-addition__list {
    display: flex;
    flex-direction: column;
    background: white;
    min-height: 452px;
    max-height: 70vh;
    padding: 30px 44px;
    gap: 10px;
    top: 75px;
    position: absolute;
    width: 100%;
    transition: opacity .2s ease-out;
    border: 1px solid #E9E8FF;
    border-radius: 0 0 16px 16px;
    overflow-y: scroll;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    &._focus {
      visibility: visible;
      opacity: 1;
    }
        
    &::-webkit-scrollbar-track {
      margin-top: 0 !important;
    }
    
    @include media-down($breakpoint-md) {
      padding: 12px;
      padding-top: 26px;
    }

  }

  .manual-addition__added-analyzes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
  }

  .manual-addition__close {
    top: 18px;
    right: 18px;
    position: absolute;
    color: $accent-color;
    &:after {
      position: absolute;
      content: '';
      cursor: pointer;
      top: -10px;
      left: -10px;
      bottom: -10px;
      right: -10px;
    }
  }

  .manual-addition__attention {
    font-size: 14px;
    line-height: 20px;
    color: $grey-2;
    margin-bottom: 14px;
    margin-top: 20px;
    @include media-down($breakpoint-md) {
      margin-bottom: 4px;
      margin-top: 6px;
      font-size: 12px;
    }
  }
</style>