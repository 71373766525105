import request from '@/utils/request.js';

export const getCoupons = async (params) => {
  const res = await request({
    method: 'get',
    url: `coupons/`,
    params,
  });
  return res.data.coupons;
};
export const getCouponCities = async () => {
  const res = await request({
    method: 'get',
    url: `coupons/cities/`,
  });
  return res.data.cities;
};
export const getCouponPartners = async () => {
  const res = await request({
    method: 'get',
    url: `coupons/partners/`,
  });
  return res.data.partners;
};
export const getPartnerOffers = async (params) => {
  const res = await request({
    method: 'get',
    url: `partner_offers/`,
    params,
  });
  return res.data.partner_offers;
};

export const getPartnerOffersAmount = async (params) => {
  const res = await request({
    method: 'get',
    url: `partner_offers/amount/`,
    params,
  });
  return res.data.amount;
};

export const getDiscountDays = async () => {
  const res = await request({
    method: 'get',
    url: `discount_days/`,
  });
  return res.data.discount_days;
};
export const getPersonalDiscounts = async () => {
  const res = await request({
    method: 'get',
    url: `personal_discounts/`,
  });
  return res.data.personal_discounts;
};
