import { __decorate } from "tslib";
import { Component, Emit, Vue } from 'vue-property-decorator';
let MainBtn = class MainBtn extends Vue {
    clickBtn() {
        return false;
    }
};
__decorate([
    Emit('click')
], MainBtn.prototype, "clickBtn", null);
MainBtn = __decorate([
    Component({})
], MainBtn);
export default MainBtn;
