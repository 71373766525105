import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let MainBtn = class MainBtn extends Vue {
    clickBtn() {
        return false;
    }
};
__decorate([
    Prop({ default: 'primary' })
], MainBtn.prototype, "type", void 0);
__decorate([
    Prop()
], MainBtn.prototype, "bcgColor", void 0);
__decorate([
    Prop({ default: 'none' })
], MainBtn.prototype, "borderColor", void 0);
__decorate([
    Prop()
], MainBtn.prototype, "width", void 0);
__decorate([
    Prop()
], MainBtn.prototype, "height", void 0);
__decorate([
    Prop()
], MainBtn.prototype, "text", void 0);
__decorate([
    Prop()
], MainBtn.prototype, "disabled", void 0);
__decorate([
    Emit('click-btn')
], MainBtn.prototype, "clickBtn", null);
MainBtn = __decorate([
    Component({})
], MainBtn);
export default MainBtn;
