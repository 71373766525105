import { getStaticVariables } from '@/api/static-variables.js';
import { getLaboratories, getUnits } from '@/api/analyzes.js';
import { getPartnerOffersAmount } from '@/api/discounts.js';
export default {
    namespaced: true,
    state: {
        staticVariables: null,
        labs: [],
        units: [],
        offersAndDiscountCounter: null
    },
    mutations: {
        setStaticVariables(state, payload) {
            state.staticVariables = payload;
        },
        setLaboratories(state, payload) {
            state.labs = payload;
        },
        setUnits(state, payload) {
            state.units = payload;
        },
        setOffersAndDiscountCounter(state, payload) {
            state.offersAndDiscountCounter = payload;
        },
    },
    actions: {
        async getStaticVariables({ commit }) {
            const data = await getStaticVariables();
            commit('setStaticVariables', data);
            return data;
        },
        async getLaboratories({ commit }) {
            const data = await getLaboratories();
            commit('setLaboratories', data);
            return data;
        },
        async getUnits({ commit }) {
            const data = await getUnits();
            commit('setUnits', data);
            return data;
        },
        async getOffersAndDiscountCounter({ commit }) {
            const data = await getPartnerOffersAmount();
            commit('setOffersAndDiscountCounter', data);
            return data;
        },
    },
    getters: {},
};
