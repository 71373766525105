import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
// import AnalyzesCard from '@/components/analyzes/AnalyzesCard.vue';
import BiomarkerItem from '@/components/analyzes/BiomarkerItem.vue';
import AnalyzeForm from '@/components/modals_new/complex/AnalyzeForm.vue';
import { bus } from '@/plugins/bus';
import { mapGetters } from 'vuex';
import AddComment from '@/components/modals/AddComment.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
let AddAnalyzes = class AddAnalyzes extends Vue {
    constructor() {
        super(...arguments);
        this.searchedValue = '';
        this.searchedList = [];
        this.addCommentModalState = false;
        this.timeout = 0;
        this.loading = false;
        this.isOpenAnalyzeForm = false;
        this.selectedBiomarker = null;
    }
    get laboratoriesList() {
        return this.$store.state.staticVariables.labs;
    }
    get biomarkersList() {
        return this.$store.state.analyzes.biomarkersList;
    }
    get units() {
        return this.$store.state.staticVariables.units;
    }
    async mounted() {
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, (id) => {
            this.addCommentModalState = !this.addCommentModalState;
            this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: id });
        });
        // TODO remove common units / labs query, get one time
        await this.$store.dispatch('analyzes/getLaboratories');
    }
    destroyed() {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'addedAnalyzes', value: [] });
    }
    searchAnalyzes(val, oldVal) {
        clearTimeout(this.timeout);
        if (val !== oldVal) {
            this.loading = true;
            this.timeout = setTimeout(async () => {
                this.searchedList = await this.$store.dispatch('analyzes/getBiomarkers', { query: val });
                this.loading = false;
            }, 1000);
        }
    }
    addAnalyzes(item) {
        this.$store.commit('analyzes/addAnalyzes', this.generateItemForAnalyzes(item));
        this.searchedValue = '';
    }
    clearSearchedValue() {
        this.searchedValue = '';
    }
    goBack() {
        this.$router.go(-1);
    }
    generateItemForAnalyzes(item) {
        return {
            biomarker: item.name,
            available_units: item.available_units,
            biomarker_group_id: item.id,
            id: Math.random().toString(36).substr(2, 9),
            laboratory: '',
            value: '',
            date: new Date(),
            comment: null,
            unit: item.unit,
            unit_id: item.unit_id,
            ranges: item.ranges,
        };
    }
    closePopup() {
        bus.$emit(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_MODAL);
        // ВЫПИЛЕНО
        this.$store.commit('analyzes/setPropertyInStore', { name: 'addAnalyzesModalState', value: false });
    }
    addCommentClose(value) {
        this.addCommentModalState = value;
    }
    onClose() {
        return false;
    }
    openAnalyzeForm(selectedBiomarker) {
        this.selectedBiomarker = selectedBiomarker;
        this.isOpenAnalyzeForm = true;
    }
    closeAnalyzeForm(status) {
        this.selectedBiomarker = null;
        this.isOpenAnalyzeForm = false;
        if (status === 'success') {
            this.searchedValue = '';
        }
    }
};
__decorate([
    Prop({ default: false, type: Boolean })
], AddAnalyzes.prototype, "isPopup", void 0);
__decorate([
    Watch('searchedValue')
], AddAnalyzes.prototype, "searchAnalyzes", null);
__decorate([
    Emit('close')
], AddAnalyzes.prototype, "onClose", null);
AddAnalyzes = __decorate([
    Component({
        components: {
            MainBtn,
            CloseBtn,
            // AnalyzesCard,
            BiomarkerItem,
            AnalyzeForm,
            AddComment,
            BackBtn,
        },
        computed: mapGetters({
            addedAnalyzes: 'analyzes/getAddedAnalyzes',
        }),
    })
], AddAnalyzes);
export default AddAnalyzes;
