import axios from 'axios';
import { showServerError } from '@/utils/showNotify.js';
// import router from './router'

const apis = {
  prod: process.env['VUE_APP_API_PROD'],
  stage: process.env['VUE_APP_API_STAGE'],
};

// TODO брать из env файла
export const isStage = false;
export const hostname = isStage ? apis.stage : apis.prod;

axios.defaults.baseURL = hostname;

const checkIsOnline = () => {
  const isOnline = window.navigator.onLine;
  // TODO
  // router.push({name: 'noConnection'})
};

const request = async (config) => {
  try {
    const res = await axios(config);
    return res;
  } catch (error) {
    // checkIsOnline()
    // TODO разлогинить при протухшем токене
    console.dir(error);
    throw error;
  }
};
export default request;
