import { __decorate } from "tslib";
import { Component, Emit, Prop, Mixins, Watch } from 'vue-property-decorator';
import AnalyzesMixin from '@/mixins/analyzes-mixin';
import BaseFormMixins from '@/mixins/base-form-mixins';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import { bus } from '@/plugins/bus';
;
import MainSelect from '@/components/UI/MainSelect.vue';
import { format } from 'date-fns';
import { parseRange } from '@/utils/utils';
import { serverDateFormat } from '@/interfaces/api.interface';
let EditAnalyzes = class EditAnalyzes extends Mixins(AnalyzesMixin, BaseFormMixins) {
    constructor() {
        super(...arguments);
        this.dataClone = {};
    }
    mounted() {
        this.updateData(this.editData);
    }
    updateData(val) {
        this.dataClone = Object.assign({
            range: this.getRanges(val.ranges),
            selectedLab: { id: val?.laboratory_id, name: val?.laboratory },
            selectedUnit: { id: val?.unit_id, name: val?.unit },
        }, val);
    }
    get unitsOptions() {
        const units = this.dataClone.available_units || this.dataClone.biomarker_available_units;
        return units && units.length ? units : this.units;
    }
    get id() {
        return this.dataClone.idAfterUpdate ? this.dataClone.idAfterUpdate : this.editData.id;
    }
    get units() {
        return this.$store.state.staticVariables.units;
    }
    async addComment() {
        await this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: this.id });
        bus.$emit(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, this.id);
    }
    validate() {
        return [this.$refs.result.validate(), this.$refs.range.validate()].includes(false);
    }
    selectUnit(val) {
        this.update('selectedUnit', val);
        this.dataClone.unit_id = val.id;
        this.dataClone.unit = val.name;
    }
    update(key, value) {
        this.$set(this.dataClone, key, value);
    }
    createDateForSave() {
        return {
            response: {
                biomarker_group_id: this.editData.biomarker_group_id,
                date: format(new Date(this.dataClone.date), serverDateFormat),
                value: this.dataClone.value,
                ranges: parseRange(this.dataClone?.range),
                unit: this.dataClone.selectedUnit?.name,
                unit_id: this.dataClone.selectedUnit?.id,
                laboratory: this.dataClone?.selectedLab?.name,
                laboratory_id: this.dataClone?.selectedLab?.id || 0,
                comment: this.dataClone?.comment ? this.dataClone?.comment : this.editData.comment,
            },
            id: this.editData.id,
        };
    }
    async save() {
        if (this.validate())
            return;
        try {
            let method = 'POST';
            if (typeof this.id === 'number') {
                method = 'PATCH';
            }
            const response = await this.createDateForSave();
            await this.$store.dispatch('analyzes/saveBiomarkers', { ...response, method });
            this.closeModal();
        }
        catch (error) {
            throw Error(error);
        }
        finally {
        }
    }
    closeModal() {
        return false;
    }
};
__decorate([
    Prop({ required: true })
], EditAnalyzes.prototype, "isShow", void 0);
__decorate([
    Prop({ required: true })
], EditAnalyzes.prototype, "editData", void 0);
__decorate([
    Watch('editData', { deep: true })
], EditAnalyzes.prototype, "updateData", null);
__decorate([
    Emit('close-modal')
], EditAnalyzes.prototype, "closeModal", null);
EditAnalyzes = __decorate([
    Component({
        components: { MainBtn, MainSelect },
    })
], EditAnalyzes);
export default EditAnalyzes;
