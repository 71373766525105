import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let FilePicker = class FilePicker extends Vue {
    get countIcon() {
        return this.type === 1 ? 'cloud-icon' : 'add-icon';
    }
    handleFileUpload() {
        return this.$refs.file?.files;
    }
    handleFileDrop(e) {
        return e.dataTransfer?.files;
    }
};
__decorate([
    Prop()
], FilePicker.prototype, "type", void 0);
__decorate([
    Prop({ default: 'Перетяните или нажмите, чтобы добавить файл' })
], FilePicker.prototype, "title", void 0);
__decorate([
    Prop({ default: 'file' })
], FilePicker.prototype, "id", void 0);
__decorate([
    Emit('add-files')
], FilePicker.prototype, "handleFileUpload", null);
__decorate([
    Emit('add-files')
], FilePicker.prototype, "handleFileDrop", null);
FilePicker = __decorate([
    Component({})
], FilePicker);
export default FilePicker;
