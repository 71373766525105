export var IPersonalArea;
(function (IPersonalArea) {
    let TabsName;
    (function (TabsName) {
        TabsName["USER_SETTINGS"] = "user-settings";
        TabsName["MEDICAL_CARD"] = "medical-card";
    })(TabsName = IPersonalArea.TabsName || (IPersonalArea.TabsName = {}));
})(IPersonalArea || (IPersonalArea = {}));
export var IUserCard;
(function (IUserCard) {
    let PasswordStrength;
    (function (PasswordStrength) {
        PasswordStrength["RISKY"] = "risky";
        PasswordStrength["QUESSABLE"] = "guessable";
        PasswordStrength["WEAK"] = "weak";
        PasswordStrength["SAFE"] = "safe";
        PasswordStrength["SECURE"] = "secure";
    })(PasswordStrength = IUserCard.PasswordStrength || (IUserCard.PasswordStrength = {}));
})(IUserCard || (IUserCard = {}));
