import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
let PreviewImg = class PreviewImg extends Vue {
    constructor() {
        super(...arguments);
        this.imgSrc = '';
        this.pdfSvg = require('@/assets/Pdf.svg');
    }
    fileChanged() {
        this.file.type !== 'application/pdf' && this.readFile();
    }
    mounted() {
        this.file.type !== 'application/pdf' && this.readFile();
    }
    readFile() {
        const reader = new FileReader();
        reader.onload = () => {
            this.imgSrc = reader.result;
        };
        reader.readAsDataURL(this.file);
    }
};
__decorate([
    Prop()
], PreviewImg.prototype, "file", void 0);
__decorate([
    Watch('file')
], PreviewImg.prototype, "fileChanged", null);
PreviewImg = __decorate([
    Component({})
], PreviewImg);
export default PreviewImg;
