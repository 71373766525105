import Vue from 'vue';
import * as $api from '@/api/_api.js';
export default {
    namespaced: true,
    state: {
        documentsList: [],
        allDocumentsList: [],
        document: {},
        addDocumentToOrder: false,
        documentResults: [],
    },
    mutations: {
        setPropertyInStore(state, { name, value }) {
            Vue.set(state, name, value);
        },
    },
    actions: {
        async getDocumentResults({ commit }, id) {
            const results = await $api.documents.getDocumentResults({ id });
            commit('setPropertyInStore', { name: 'documentResults', value: results });
        },
        async updateBiomarkerInResult({ dispatch }, { item, biomarkerId, docId, }) {
            const method = 'PATCH';
            const response = {
                response: {
                    biomarker_group_id: item.id,
                    biomarker: item.name,
                    ranges: item.ranges,
                    unit: item.unit,
                    unit_id: item.unit_id,
                    available_units: item.available_units,
                    categories: item.categories,
                },
                id: biomarkerId,
            };
            await dispatch('analyzes/saveBiomarkers', { ...response, method }, { root: true });
            await dispatch('getDocumentResults', docId);
        },
    },
    getters: {},
};
