import request from '@/utils/request.js';
import { format } from 'date-fns';

export const getLaboratories = async () => {
  const res = await request({
    method: 'get',
    url: `analyzes/laboratories/`,
  });
  return res.data.laboratories;
};

export const getRubrics = async () => {
  const res = await request({
    method: 'get',
    url: `analyzes/rubrics/`,
  });
  return res.data.rubrics;
};

export const getRubric = async ({ rubric_id }) => {
  const res = await request({
    method: 'get',
    url: `analyzes/rubrics/${rubric_id}`,
  });
  return res.data;
};

export const getRubricsResults = async ({ subrubrics }) => {
  const res = await request({
    method: 'get',
    url: `analyzes/rubrics/results`,
    params: {
      subrubrics,
      count: 1000,
    },
  });
  return res.data.rubrics;
};

export const getCategories = async () => {
  const res = await request({
    method: 'get',
    url: `/analyzes/categories/`,
  });
  return res.data.categories;
};

export const getCategoriesResults = async ({ category_id }) => {
  const res = await request({
    method: 'get',
    url: `/analyzes/categories/${category_id}/results/`,
  });
  return res.data;
};

export const getUnits = async () => {
  const res = await request({
    method: 'get',
    url: `analyzes/units/`,
  });
  return res.data.units;
};

export const getBiomarkerGroups = async ({ query = '', offset = 0, count = 20 }) => {
  const res = await request({
    method: 'get',
    url: `analyzes/groups/`,
    params: { query, offset, count },
  });
  return res.data.biomarker_groups;
};

export const getBiomarkerGroupResults = async ({ biomarker_group_id, start_date, end_date }) => {
  const today = new Date();

  const res = await request(
    {
      method: 'get',
      url: `analyzes/groups/${biomarker_group_id}/results/`,
      params: {
        start_date: format(start_date || new Date('1970-01-01'), 'yyyy-MM-dd'),
        end_date: format(end_date || today, 'yyyy-MM-dd'),
      },
    },
    true
  );
  return res.data;
};

export const getBiomarkerResults = async ({ biomarker_id }) => {
  const today = new Date();
  const end_date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  const res = await request(
    {
      method: 'get',
      url: `analyzes/biomarkers/${biomarker_id}/results/`,
      params: { start_date: '1970-1-1', end_date },
    },
    true
  );
  return res.data;
};

export const getResults = async ({
  offset = 0,
  count = 20,
  sort_type = '-date',
  category = null,
  rubrics = null,
  query = null,
}) => {
  const res = await request({
    method: 'get',
    url: `analyzes/results/`,
    params: { offset, count, sort_type, category, rubrics, query },
  });
  return res.data;
};

export const deleteResult = async ({ id }) => {
  const res = await request({
    method: 'delete',
    url: `analyzes/results/${id}`,
  });
  return res.data;
};

export const createResult = async ({ data }) => {
  const res = await request({
    method: 'post',
    url: `analyzes/results/`,
    data,
  });
  return res.data;
};

export const changeResult = async ({ id, data }) => {
  const res = await request({
    method: 'patch',
    url: `analyzes/results/${id}`,
    data,
  });
  return res.data;
};

export const getAnalyzeServiceCatalog = async ({ id }) => {
  const res = await request({
    method: 'get',
    url: `/analyzes/groups/${id}/services/`,
  });
  return res.data.analyze_services;
};
