<template lang="pug">
.BaseCounter(v-if="count") {{count}}
</template>

<script>
export default {
  name: 'BaseCounter',
  props: {
    count: Number
  }
}
</script>

<style lang="scss">
.BaseCounter {
  width: 18px;
  height: 18px;
  background: $accent-orange;
  border-radius: 100%;
  font-weight: 500;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>