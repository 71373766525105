import * as $api from '@/api/_api.js';
export default {
    namespaced: true,
    state: {
        documentList: [],
        document: {},
        documentTypes: [],
    },
    mutations: {
        changeDocumentList(state, data) {
            state.documentList = data;
        },
        addItemDocumentList(state, data) {
            state.documentList.push(data);
        },
        deleteItemDocumentList(state, index) {
            state.documentList.splice(index, 1);
        },
        changeDocumentDocumentList(state, { data, index }) {
            Object.keys(data).forEach((key) => {
                state.documentList[index][key] = data[key];
            });
        },
        changeDocument(state, data) {
            state.document = data;
        },
        changeDocumentTypes(state, data) {
            state.documentTypes = data;
        },
        deleteFileDocumentList(state, data) {
            const { documentIndex, fileIndex } = data;
            state.documentList[documentIndex].files.splice(fileIndex, 1);
        },
    },
    actions: {
        async loadDocuments({ commit, dispatch }) {
            const response = await $api.documents.getDocuments();
            commit('changeDocumentList', response);
            return true;
        },
        async loadDocument({ commit, dispatch }, id) {
            const response = await $api.documents.getDocument({ id });
            commit('changeDocument', response);
            return true;
        },
        async createDocument({ commit, dispatch, rootState }, payload) {
            const fileList = payload.fileList;
            delete payload.fileList;
            const response = await $api.documents.createDocument(payload);
            const data = response.data;
            await dispatch('createFiles', { id: data.id, fileList });
            const documentResponse = await $api.documents.getDocument({ id: data.id });
            const documentData = documentResponse;
            commit('addItemDocumentList', documentData);
            // const addToOrder = rootState.documents.addDocumentToOrder;
            // if (addToOrder) {
            //   const { id, document_ids } = rootState.orders.orderedService;
            //   const docIds = [...new Set([...document_ids, data.id])];
            //   dispatch('orders/changeOrderedService', { id, documentIds: docIds }, { root: true });
            // }
            return data;
        },
        async deleteDocument({ commit, dispatch, state }, id) {
            await $api.documents.deleteDocument({ id });
            const deletedDocumentIndex = state.documentList.findIndex((document) => document.id === id);
            commit('deleteItemDocumentList', deletedDocumentIndex);
            return true;
        },
        async editDocument({ commit, dispatch, state }, payload) {
            const response = await $api.documents.changeDocument(payload);
            const data = response.data;
            const index = state.documentList.findIndex((document) => document.id === payload.id);
            if (payload.singleDoc) {
                if (payload.type_doc === 1) {
                    commit('changeDocument', data);
                }
            }
            else {
                commit('changeDocumentDocumentList', { data, index });
            }
            return response;
        },
        async createFiles({ dispatch }, payload) {
            await $api.documents.createFile(payload);
            return true;
        },
        async deleteFile({ commit, dispatch, state }, payload) {
            await $api.documents.deleteFile(payload);
            const documentIndex = state.documentList.findIndex((document) => document.id === payload.documentId);
            const fileIndex = state.documentList[documentIndex].files.findIndex((file) => file.id === payload.fileId);
            commit('deleteFileDocumentList', { documentIndex, fileIndex });
            return true;
        },
    },
};
