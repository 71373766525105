<template lang="pug">
.popup-btn(@click="isOpenHelpModal = true" :class="{_active: isOpenHelpModal}")
  slot(name="button")
  q-dialog(v-model="isOpenHelpModal" position="bottom" :content-class="mobile ? 'popupMobile' : modalClass")
    .select-modal
      slot(name="items")
</template>

<script>
import MenuItem from '@/components/layout/MenuItem.vue';

export default {
  components: {
    MenuItem
  },
  data(){
    return {
      isOpenHelpModal: false,
    }
  },
  props:{
    modalClass: String,
    mobile: Boolean
  }

}
</script>


<style lang="scss">
  .popup-btn {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    &__icon {
      color: $light-white;
    }

    &__text {
      color: $light-white;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
    }
  }
  
  .select-modal {
    display: flex;
    flex-direction: column;
    gap: 2px;
    border-radius: 20px!important;
    padding: 14px;
    background: white;
  }

  .select-modal__item {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    text-decoration: none;
    color: $grey-1;
    &:hover, &.router-link-active {
      background: $light-background;
    }
  }
.popupMobile{
  .q-dialog__inner {
      left: 20px;
      right: 20px;
      bottom: 80px
   }
}



</style>