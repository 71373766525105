import { render, staticRenderFns } from "./ManualAddition.vue?vue&type=template&id=61339d2b&"
import script from "./ManualAddition.vue?vue&type=script&lang=js&"
export * from "./ManualAddition.vue?vue&type=script&lang=js&"
import style0 from "./ManualAddition.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QDialog});qInstall(component, 'directives', {ClosePopup});
