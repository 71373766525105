import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
let ConfirmModal = class ConfirmModal extends Vue {
    get isVisible() {
        return this.$store.state.confirm.isVisible;
    }
    onCancel() {
        this.$store.dispatch('confirm/cancel');
    }
    onConfirm() {
        this.$store.dispatch('confirm/confirm');
    }
};
ConfirmModal = __decorate([
    Component({
        components: {
            MainBtn,
        },
    })
], ConfirmModal);
export default ConfirmModal;
