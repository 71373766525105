<template>
  <q-input 
    v-bind="$attrs"
    :value="value" 
    @input="$emit('input', $event)" 
    @focus="$emit('focus')"
    debounce="1000"
    rounded 
    outlined 
    bg-color="white" 
    class="search-input"
    :class="{'_focus': focus}"
    ref="search-input">
    <template v-slot:prepend>
      <icon color="#7C74E9" name="search-icon" width="12px" style="margin-left: 10px"/>
    </template>
    <template v-slot:append>
      <div @click="onClose" class="search-input__close cursor-area" :class="{'_focus': focus}">
        <icon  color="#7C74E9" name="close-icon" width="8px" />
      </div>
    </template>
  </q-input>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ""
    },
    focus: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClose() {
      this.$emit('blur')
      this.$refs['search-input'].blur()
    }
  }
}
</script>

<style lang="scss">
.search-input {
  position: relative;
  z-index: 2;

  .q-field__control {
    border-radius: 16px !important;
    &:before, &:after {
      border: none !important;
    }
  }
  
  &._focus {
    .q-field__control {
      box-shadow: 0px 4px 15px rgba(22, 20, 44, 0.06);
    }
  }
}

.search-input__close {
  margin-right: 20px;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  translate: opacity .2s ease;
  &._focus {
    opacity: 1;
    visibility: visible;
  }
}
</style>