<template lang="pug">
q-dialog(content-class="_scroll-in-window" 
  v-bind="$attrs"
  :value="value" 
  @hide="close" 
  transition-show="fade" 
  transition-hide="fade")
  .message-modal(:class="modificators")
    .message-modal__title.h3(v-if="title") {{title}}
    slot(name="subtitle")
    .message-modal__text(v-if="isBodySlotExist"): slot(name="body")
    .message-modal__buttons(:class="modificators" v-if="!hideOkButton || !!this.$slots.buttons")
      slot(name="buttons"): BaseBtn(v-if="!hideOkButton" @click="close" size="md" type="dark" :style="wideButtons ? {margin: '0 auto'} : ''") {{buttonText}}
    .message-modal__close(@click="close")
      .cursor-area._svg-wrapper: icon(name="close-icon" color="#7C74E9" height="10")
</template>

<script>
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
export default {
  components: {MainBtn},
  props: {
    value: {
      type: Boolean,
    }, 
    title: {
      type: String,
    }, 
    buttonText: {
      type: String,
      default: 'Все понятно'
    }, 
    hideOkButton: {
      type: Boolean,
    }, 
    rowButtons: {
      type: Boolean,
    },
    wideButtons: {
      type: Boolean,
    },
    size: {
      type: String, //sm, md, lg, xs, auto
      default: 'md'
    }
  },
  methods: {
    close(){
      this.$emit('close')
      this.$emit('input', false); 
    }
  },
  computed: {
    modificators() {
      return {
        ['_row-buttons']: this.rowButtons,
        ['_wide-buttons']: this.wideButtons,
        [`_${this.size}`]: this.size,
      }
    },
    isBodySlotExist() {
      return !!this.$slots.body
    }
  }
}
</script>

<style lang="scss">
.message-modal {
  border-radius: 15px !important;
  // TODO GLOBAL VAR RADIUS
  width: 427px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  align-items: center;
  position: relative;
  &._lg {
    padding: 40px 46px;
    @include media-up($breakpoint-md){
      width: 550px;
    }
  }
  &._md {
    padding: 40px 46px;
  }
  &._sm {
    padding: 30px;
  }
  &._xs {
    padding: 16px;
    padding-top: 36px;
  }
  &._auto {
    width: auto;
    padding: 24px;
  }
}
.message-modal__text {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #2D2D2D;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.message-modal__title {
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: #2D2D2D;
}
.message-modal__tip {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 14px;
  background: $light-background;
  border-radius: 18px;
  &._icon {
    font-size: 24px;
    justify-content: center;
    padding: 0;
    width: 76px;
    height: 76px;
    align-self: center;
  }
}

.message-modal__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
  .message-modal._lg &{
    @include media-up($breakpoint-md){
      // width: 318px;
    }
  }
  &._row-buttons {
    flex-direction: row;
    width: auto;
  }
  &._wide-buttons {
    width: 100%;
  }

  .q-btn {
    flex-grow: 1;
  }
  
}
.message-modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>