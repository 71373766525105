import Vue from 'vue';
import App from './App.vue';
import * as $api from '@/api/_api.js';
Vue.prototype.$api = $api;
import router from './router';
import store from './store';
import DefaultLayout from './layouts/DefaultLayout.vue';
import AuthLayout from './layouts/AuthLayout.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import BaseBtn from '@/components/UI/buttons/BaseBtn.vue';
import './plugins/quasar';
import './plugins/vue-awesome';
import './plugins/vueDonutChart';
import './plugins/date-fns';
import './plugins/axios';
import './plugins/bus';
import './plugins/vue.cookes';
import './styles/main.scss';
Vue.component('defaultLayout', DefaultLayout);
Vue.component('authLayout', AuthLayout);
Vue.component('MainBtn', MainBtn);
Vue.component('BaseBtn', BaseBtn);
Vue.config.productionTip = false;
import PortalVue from 'portal-vue';
Vue.use(PortalVue);
import { isStage } from '@/utils/request.js';
import VueYandexMetrika from 'vue-yandex-metrika';
Vue.use(VueYandexMetrika, {
    id: 86350227,
    router: router,
    env: !isStage && process.env.NODE_ENV,
    options: {
        webvisor: 1,
    },
});
import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
    enabled: !isStage && process.env.NODE_ENV === 'production',
    config: {
        id: 'G-GK6BY8ZWVK',
    },
}, router);
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
Sentry.init({
    Vue,
    dsn: "https://959c6fef02234de697c1d9d8d022e8e2@o1309182.ingest.sentry.io/6555128",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ["my.labstory.app", /^\//],
        }),
    ],
    tracingOptions: {
        trackComponents: true
    },
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
