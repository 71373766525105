import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import AddResearch from '@/views/DashboardPage/AddResearch.vue'; //TODO
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
let AddResearchModal = class AddResearchModal extends Vue {
    constructor() {
        super(...arguments);
        this.isFileModalOpen = false;
        this.isAnalyzeFileModalOpen = false;
    }
    closeModal() {
        return false;
    }
    onClose() {
        bus.$emit(IAnalyzes.BusEvents.ADD_RESEARCH_OPEN);
    }
    toggleFileModal(state) {
        this.$store.commit('documents/setPropertyInStore', { name: 'addDocumentToOrder', value: false });
        this.isFileModalOpen = state;
    }
    toggleAnalyzeFileModal(state) {
        this.$store.commit('documents/setPropertyInStore', { name: 'addDocumentToOrder', value: false });
        this.isAnalyzeFileModalOpen = state;
    }
};
__decorate([
    Prop({ required: true })
], AddResearchModal.prototype, "isShow", void 0);
__decorate([
    Emit('close-modal')
], AddResearchModal.prototype, "closeModal", null);
AddResearchModal = __decorate([
    Component({
        components: {
            AddResearch,
            AddFileModal,
            CloseBtn,
        },
    })
], AddResearchModal);
export default AddResearchModal;
