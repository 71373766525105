<template lang="pug">
.analyzes-item
  .analyzes-item__title-wrapper
    .analyzes-item__title {{title}}
    .analyzes-item__synonyms(v-if="search_by_synonym" v-html="coloredSynonyms")
  .analyzes-item__biomaterial-wrapper
    BaseBadge(v-if="biomaterial" :value="biomaterial")
  q-btn.analyzes-item__button(padding='10px 22px' flat)
    icon.analyzes-item__button-icon(width='10' name='add-icon')
    span.analyzes-item__button-text Добавить анализ
</template>

<script>
import BaseBadge from '@/components/UI/BaseBadge.vue';
export default {
  props: {
    title: String,
    biomaterial: String,
    all_synonyms: String,
    search_by_synonym: Boolean,
    query: String,
  },
  components: { BaseBadge },
  computed: {
    coloredSynonyms(){
      const startIndex = this.all_synonyms.toLowerCase().indexOf(this.query.toLowerCase());
      const array = this.all_synonyms.split("");
      array.splice(startIndex, 0, "<span class='text-primary'>");
      array.splice(startIndex + this.query.length + 1, 0, "</span>");
      return array.join("");
    }
  }
}
</script>

<style lang="scss">

.analyzes-item {
  background: $light-background;
  border-radius: 100px;
  padding-left: 30px;
  padding-right: 10px;
  min-height: 72px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  align-items: center;
  transition: 0.2s linear;
  cursor: pointer;
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  justify-content: flex-start;
  border: 1px solid transparent;
  
  &:hover {
    background: white;
    border-color: #E9E8FF;
  }

  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
    height: auto;
    min-height: auto;
    padding: 14px 18px;
    gap: 10px;
    border-radius: 20px;
  }
}

.analyzes-item__title-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.analyzes-item__synonyms {
  font-size: 12px;
  line-height: 15px;
  color: $grey-4;
  @include trim-text(2)
}


.analyzes-item__button {
  margin-left: auto;
  background: $light-background !important;
  border-radius: 22px !important;
  box-shadow: none !important;
  @include media-down($breakpoint-md) {
    margin-left: 0;
    justify-self: flex-start;
    .q-btn__wrapper {
      padding: 0 !important;
    }
  }
}

.analyzes-item__button-icon {
  margin-right: 10px;
  color: $accent-color;
}

.analyzes-item__button-text {
  color: $black-03;
  text-transform: none;
  font-weight: normal;
  font-size: 12px;
  
}


</style>;
