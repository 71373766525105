import { IRouter } from '@/interfaces/router.interface';
var ROUTE_PATH = IRouter.ROUTE_PATH;
var ROUTE_NAME = IRouter.ROUTE_NAME;
export var ILayout;
(function (ILayout) {
    let LayoutPattern;
    (function (LayoutPattern) {
        LayoutPattern["DESKTOP"] = "LHH LPr LFF";
        LayoutPattern["MOBILE"] = "HHH lPR FFF";
    })(LayoutPattern = ILayout.LayoutPattern || (ILayout.LayoutPattern = {}));
    let Breakpoint;
    (function (Breakpoint) {
        Breakpoint[Breakpoint["MOBILE"] = 767] = "MOBILE";
        Breakpoint[Breakpoint["MOBILE_SMALL"] = 575] = "MOBILE_SMALL";
        Breakpoint[Breakpoint["PRE_MD"] = 1110] = "PRE_MD";
    })(Breakpoint = ILayout.Breakpoint || (ILayout.Breakpoint = {}));
    let LayoutType;
    (function (LayoutType) {
        LayoutType["AUTH"] = "authLayout";
        LayoutType["DEFAULT"] = "defaultLayout";
    })(LayoutType = ILayout.LayoutType || (ILayout.LayoutType = {}));
})(ILayout || (ILayout = {}));
export var IMenu;
(function (IMenu) {
    IMenu.MAIN_MENU = [
        {
            name: ROUTE_NAME.INDEX_PAGE,
            link: ROUTE_PATH[ROUTE_NAME.INDEX_PAGE],
            icon: 'home-icon',
            text: 'Главная',
            exact: true,
        },
        {
            name: ROUTE_NAME.ALL_ANALYZES,
            link: ROUTE_PATH[ROUTE_NAME.ALL_ANALYZES],
            icon: 'menu-analyzes',
            text: 'Анализы',
            exact: false,
        },
        // убираем пока данный элемент меню
        // {
        //   name: ROUTE_NAME.APPOINTMENT_PAGE,
        //   link: ROUTE_PATH[ROUTE_NAME.APPOINTMENT_PAGE],
        //   icon: 'manual-icon',
        //   text: 'Приемы',
        //   exact: false,
        // },
        {
            name: ROUTE_NAME.STORAGE_PAGE,
            link: ROUTE_PATH[ROUTE_NAME.STORAGE_PAGE],
            icon: 'menu-doc',
            text: 'Документы',
            exact: false,
        },
        {
            name: ROUTE_NAME.ORDER_HISTORY,
            link: ROUTE_PATH[ROUTE_NAME.ORDER_HISTORY],
            icon: 'orders-icon',
            text: 'Заказы',
            exact: false,
        },
        // {
        //   name: ROUTE_NAME.DISCOUNT_PAGE,
        //   link: ROUTE_PATH[ROUTE_NAME.DISCOUNT_PAGE],
        //   icon: 'discounts',
        //   text: 'Скидки и предложения',
        //   exact: false,
        //   width: 22,
        //   height: 26,
        // },
        {
            name: ROUTE_NAME.PAGE_PERSONAL_AREA,
            link: ROUTE_PATH[ROUTE_NAME.PAGE_PERSONAL_AREA],
            icon: 'profile',
            text: 'Профиль',
            exact: false,
        },
    ];
    IMenu.PERSONAL_AREA_MENU = [
        {
            name: ROUTE_NAME.PROFILE_PAGE,
            icon: 'profile-icon',
            text: 'Данные профиля',
            link: ROUTE_PATH[ROUTE_NAME.PROFILE_PAGE],
            exact: false,
        },
        {
            name: ROUTE_NAME.MEDICAL_CARD_PAGE,
            icon: 'paper-icon',
            text: 'Медицинская карта',
            link: ROUTE_PATH[ROUTE_NAME.MEDICAL_CARD_PAGE],
            exact: false,
        },
        {
            name: ROUTE_NAME.PASSWORD_CHANGE_PAGE,
            icon: 'password',
            text: 'Сменить пароль',
            link: ROUTE_PATH[ROUTE_NAME.PASSWORD_CHANGE_PAGE],
            exact: false,
        },
    ];
})(IMenu || (IMenu = {}));
