import request from '@/utils/request.js';
import store from '@/store';

export const downloadPdf = async ({ sid }) => {
  const { patient_id, name, surname, patronymic } = store.state.personalArea.patient;
  const res = await request({
    method: 'get',
    url: `https://web.labstori.ru/rezults`,
    // url: `https://172.22.3.19/rezults`,
    responseType: 'blob',
    params: {
      fio: `${surname} ${name} ${patronymic}`,
      id: patient_id,
      sid,
      pdf: true,
      pat: true,
      header: true,
    },
  });
  return res;
};
