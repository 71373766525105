import Vue from 'vue';
import '../styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import lang from 'quasar/lang/ru.js';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Notify from 'quasar/src/plugins/Notify.js';
import Quasar from 'quasar/src/vue-plugin.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
;
Vue.use(Quasar, {
    config: {},
    components: {
        QTooltip,
    },
    plugins: {
        Notify,
    },
    lang: lang,
});
