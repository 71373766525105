import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import UserCard from '@/views/PersonalArea/userCard/UserCard.vue';
import { IRouter } from '@/interfaces/router.interface';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import LockLabel from '@/components/LockLabel.vue';
import ButtonDivider from '@/components/UI/ButtonDivider.vue';
import BaseCounter from '@/components/UI/BaseCounter.vue';
let Header = class Header extends Vue {
    constructor() {
        super(...arguments);
        this.showPopup = false;
    }
    get userCardPopupIsActive() {
        return this.$route.name !== ROUTE_NAME.PAGE_PERSONAL_AREA;
    }
    get avatar() {
        return this.$store.state.personalArea.patient?.avatar || require('@/assets/image-placeholder-350x350.png');
    }
    get isMainPage() {
        return ROUTE_NAME.INDEX_PAGE === this.$route.name;
    }
    userCardClose() {
        this.showPopup = false;
    }
    goBack() {
        this.$router.go(-1);
    }
    togglePopup() {
        this.showPopup = !this.showPopup;
    }
    openResearchModal() {
        bus.$emit(IAnalyzes.BusEvents.ADD_RESEARCH_OPEN);
    }
};
Header = __decorate([
    Component({
        components: {
            MainBtn,
            UserCard,
            BackBtn,
            LockLabel,
            ButtonDivider,
            BaseCounter
        },
    })
], Header);
export default Header;
