import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
let CheckboxInput = class CheckboxInput extends Vue {
    // TODO replace method on all app
    changeValue() {
        this.input();
        return !this.value;
    }
    input() {
        return !this.value;
    }
};
__decorate([
    Prop({ required: true })
], CheckboxInput.prototype, "value", void 0);
__decorate([
    Prop({ required: false, default: () => { return Math.random().toString(36).substr(2, 9); } })
], CheckboxInput.prototype, "id", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "label", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "disabled", void 0);
__decorate([
    Prop()
], CheckboxInput.prototype, "customClass", void 0);
__decorate([
    Prop({ default: '#7C74E9' })
], CheckboxInput.prototype, "borderColor", void 0);
__decorate([
    Prop({ default: '#7C74E9' })
], CheckboxInput.prototype, "color", void 0);
__decorate([
    Emit('change-value')
], CheckboxInput.prototype, "changeValue", null);
__decorate([
    Emit('input')
], CheckboxInput.prototype, "input", null);
CheckboxInput = __decorate([
    Component({})
], CheckboxInput);
export default CheckboxInput;
