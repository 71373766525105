import request from '@/utils/request.js';

export const getMediaDocument = async (url) => {
  const res = await request({
    method: 'get',
    url,
    responseType: 'blob',
  });
  return res;
};
