import Vue from 'vue';
import * as $api from '@/api/_api.js';
import store from '..';
import router from '@/router';
import { IRouter } from '@/interfaces/router.interface';
export default {
    namespaced: true,
    state: {
        analyzesForAddList: [],
        addedAnalyzes: [],
        addedAnalyzeItems: [],
        checkBoxValues: {},
        compareMode: false,
        laboratoriesList: [],
        biomarkersList: [],
        commentAnalyzesId: null,
        comment: '',
        comparingItems: [],
        analyzeBiomarkerList: null,
        analyzeResultsList: [],
        analyzeResult: {},
        analyzeRubricsList: [],
        defaultAnalyzeResultsList: [],
        analyzeRubric: {},
        selectedRubrics: [],
        selectedRubricIds: [],
        biomarkerResults: {},
        addAnalyzesModalState: false,
        editAnalyzeModalState: false,
        changingAnalyzeId: null,
        isDeleting: false,
        categories: [],
    },
    mutations: {
        setPropertyInStore(state, { name, value }) {
            Vue.set(state, name, value);
        },
        addAnalyzes(state, item) {
            const items = state.addedAnalyzes;
            items.push(item);
            state.addedAnalyzes = items;
        },
        updateBiomarkerAfterSave(state, { id, biomarker }) {
            const index = state.addedAnalyzes.findIndex((item) => item.id === id);
            if (index !== -1) {
                state.addedAnalyzes[index] = biomarker;
            }
        },
        deleteBiomarker(state, id) {
            const items = state.addedAnalyzes;
            state.addedAnalyzes = items.filter((item) => item.id !== id);
        },
        addAnalyze(state, item) {
            const items = state.addedAnalyzeItems;
            items.push(item);
            state.addedAnalyzeItems = items;
        },
        setSelectedRubricIds(state, item) {
            state.selectedRubricIds.includes(item)
                ? (state.selectedRubricIds = state.selectedRubricIds.filter((el) => el !== item))
                : (state.selectedRubricIds = [...state.selectedRubricIds, item]);
        },
        setComparingItems(state, id) {
            const MAX_AVAILABLE_ITEMS = 4;
            const MIN_AVAILABLE_ITEMS = 1;
            if (state.comparingItems.includes(id)) {
                if (state.comparingItems.length <= MIN_AVAILABLE_ITEMS)
                    return;
                state.comparingItems = state.comparingItems.filter((el) => el !== id);
            }
            else {
                if (state.comparingItems.length >= MAX_AVAILABLE_ITEMS)
                    return;
                state.comparingItems = [...state.comparingItems, id];
            }
        },
        clearComparingItems(state) {
            state.comparingItems = [];
        },
    },
    actions: {
        async init({ dispatch }) {
            await dispatch('getBiomarkers');
            await dispatch('getLaboratories');
        },
        async getBiomarkers({ dispatch, commit }, payload) {
            const biomarker_groups = await $api.analyzes.getBiomarkerGroups(payload);
            commit('setPropertyInStore', { name: 'biomarkersList', value: biomarker_groups });
            return biomarker_groups;
        },
        async saveBiomarkers({ dispatch, commit }, { response, id, method, }) {
            let biomarker;
            if (method === 'PATCH') {
                biomarker = await $api.analyzes.changeResult({ id, data: response });
            }
            else {
                biomarker = await $api.analyzes.createResult({ data: response });
            }
            const doc = store.state.storage.document;
            commit('updateBiomarkerAfterSave', { id, biomarker });
            typeof doc.id === 'number' &&
                dispatch('documents/getDocumentResults', doc.id, { root: true });
            if (router.currentRoute.name === IRouter.ROUTE_NAME.ANALYZES_PAGE) {
                await dispatch('getBiomarkerResults', {
                    biomarkerId: router.currentRoute.params.id,
                    start_date: new Date('01/01/1970'),
                    end_date: new Date(),
                });
            }
            return { status: true, data: biomarker };
        },
        async deleteBiomarker({ dispatch, commit }, id) {
            const res = await $api.analyzes.deleteResult({ id });
            commit('deleteBiomarker', id);
            return res;
        },
        async addComment({ commit, getters }, { id, comment }) {
            const items = getters['getAddedAnalyzes'];
            const index = items.findIndex((item) => item.id === id);
            if (index !== -1) {
                items[index].comment = comment;
            }
            commit('setPropertyInStore', { name: 'addedAnalyzes', value: items });
            commit('setPropertyInStore', { name: 'comment', value: comment });
        },
        deleteAnalyzes({ state, commit }, id) {
            const items = state.addedAnalyzes;
            commit('setPropertyInStore', {
                name: 'addedAnalyzes',
                value: items.filter((item) => item.id !== id),
            });
        },
        async analyzeBiomarkers({ commit }, { count, offset }) {
            const data = await $api.analyzes.getBiomarkerGroups({ count, offset });
            commit('setPropertyInStore', { name: 'analyzeBiomarkerList', value: data });
        },
        setCheckBoxValues({ state, commit }) {
            const rubrics = [...state.analyzeRubricsList];
            const values = {};
            const addValue = (arr) => {
                arr.forEach((el) => {
                    values[el.id] = false;
                    if (el.subrubrics?.length) {
                        addValue(el.subrubrics);
                    }
                });
            };
            addValue(rubrics);
            commit('setPropertyInStore', { name: 'checkBoxValues', value: values });
        },
        async analyzeResultsList({ commit, dispatch }) {
            const data = await $api.analyzes.getResults({
                offset: 0,
                count: 20,
                sort_type: '-date',
                category: null,
                rubrics: null,
                query: null,
            });
            commit('setPropertyInStore', { name: 'analyzeResultsList', value: data?.results });
            commit('setPropertyInStore', { name: 'defaultAnalyzeResultsList', value: data?.results });
            return data.results;
        },
        async analyzeRubrics({ commit, dispatch }) {
            const rubrics = await $api.analyzes.getRubrics();
            commit('setPropertyInStore', { name: 'analyzeRubricsList', value: rubrics });
        },
        async getBiomarkerResults({ commit, dispatch }, payload) {
            // TODO два бага - передавать дату
            // 1 старый метод
            //   getBiomarkerResults(payload: {
            //     biomarkerId: number | string;
            //     start_date: Date;
            //     end_date: Date;
            //   }): Promise<AxiosResponse<IAnalyzesApi.IBiomarkerResults>> {
            //     const { start_date, end_date } = payload;
            //     const startDate = `${start_date.getFullYear()}-${
            //       start_date.getMonth() + 1
            //     }-${start_date.getDate()}`;
            //     console.log(start_date);
            //     const endDate = `${end_date.getFullYear()}-${end_date.getMonth() + 1}-${end_date.getDate()}`;
            //     const query = `?start_date=${startDate}&end_date=${endDate}`;
            //     return Axios.get(`${url}analyzes/groups/${payload.biomarkerId}/results/${query}`) as Promise<
            //       AxiosResponse<IAnalyzesApi.IBiomarkerResults>
            //     >;
            //   }
            // }
            const { start_date, end_date } = payload;
            // const endDate = `${end_date.getFullYear()}-${end_date.getMonth() + 1}-${end_date.getDate()}`;
            // 2 ошибка .min в ResultsChart
            const data = await $api.analyzes.getBiomarkerGroupResults({
                biomarker_group_id: payload.biomarkerId,
                start_date: start_date,
                end_date: end_date,
            });
            console.log(data);
            commit('setPropertyInStore', { name: 'biomarkerResults', value: data });
            return data;
        },
    },
    getters: {
        getAddedAnalyzes(state) {
            return state.addedAnalyzes;
        },
        getAnalyzeById(state) {
            return (id) => state.addedAnalyzes.find((item) => item.id === id);
        },
        checkedArr(state) {
            return Object.values(state.checkBoxValues).filter((el) => el).length;
        },
        getSelectedRubricIds(state) {
            return state.selectedRubricIds;
        },
        getBiomarkerResults(state) {
            const biomarker = state.biomarkerResults;
            const results = biomarker?.results?.map((el) => {
                return { ...el, visible: true };
            });
            return { ...biomarker, results };
        },
        getAllBiomarkerResults(state) {
            const biomarker = state.biomarkerResults;
            const results = biomarker?.results?.map((el) => {
                return { ...el, visible: true };
            });
            return { ...biomarker, results };
        },
    },
};
