import Notify from 'quasar/src/plugins/Notify.js';;

Notify.registerType('custom-error', {
  icon: 'error',
  color: 'white',
  textColor: 'grey-2',
  classes: 'notifyCustom',
});

export const showServerError = (error) => {
  const htmlError = error.errorData;
  const errorMessage = JSON.stringify(htmlError || error.message || error.response?.data?.detail);
  Notify.create({
    message: `Server error: ${errorMessage}`,
    type: 'custom-error',
    html: !!htmlError,
    classes: 'notifyCustom server-error',
    timeout: htmlError ? 10000 : 5000,
    position: 'top',
  });
  console.dir(error);
};

export const showClientError = (message) => {
  Notify.create({
    message: message,
    type: 'custom-error',
  });
};

export const showSuccess = (message) => {
  Notify.create({
    message,
    type: 'positive',
  });
};
