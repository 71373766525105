// TODO forminput to component
<template>
  <div class="analyze-form scrollable" @click="userStartedManualAdding">
    <template v-if="!withFocusOnComment">
          <BackBtn class="back-btn" @go-back="$emit('close', 'cancel')" style="padding: 0"/>
    <div>{{isNew ? 'Добавить анализ' : 'Редактировать анализ'}}</div>
    <div class="analyze-form__name">{{isNew ? formProp.title : formProp.name || formProp.biomarker}}</div>

    <div v-if="!isEnum" class="analyze-form__row-values">
      <FormInput label="Значение" v-model="result.value" @input="valueFilter" name="analyze-form__value" placeholder="Например, 12.156"/>
      <FormInput label="Степень" :value="result.power_value" @input="powerValueFilter" v-if="checkboxes.power_value" name="analyze-form__power_value" class="analyze-form__power" suffix="10^"/>
      <FormInput label="Единицы измерения" type="select" v-model="currentUnitOption" :options="unitOptions" name="analyze-form__units" />
    </div>

    <div v-if="isEnum" class="analyze-form__row-values _enum">
      <FormInput label="Результат" type="select" placeholder="Выберите результат" v-model="currentEnumValueOption" :options="enumOptions" optionLabel="value" name="analyze-form__result"/>
      <FormInput label="Количественный результат" v-model.number="result.num_value" v-if="checkboxes.num_value" name="analyze-form__number-value"/>
      <FormInput label="Степень" :value="result.power_value" @input="powerValueFilter" v-if="checkboxes.power_value && checkboxes.num_value" name="analyze-form__power_value" class="analyze-form__power" suffix="10^"/>
    </div>

    <div class="analyze-form__checkboxes">
      <CheckboxInput v-model="checkboxes.num_value" label="Есть количественный результат" v-if="isEnum" @input="result.num_value = null"/>
      <CheckboxInput v-if="checkboxes.num_value || !isEnum" v-model="checkboxes.power_value" label="Значение со степенью" @input="result.power_value = null" />
      <CheckboxInput v-model="checkboxes.rel_value" label="Относительный результат" v-if="isEnum" @input="result.rel_value = null"/>
    </div>

    <FormInput label="Значение относительного результата" v-model="result.rel_value" v-if="isEnum && checkboxes.rel_value" name="analyze-form__relative-value"/>

    <q-separator />

    <div v-if="!isEnum" class="analyze-form__tip">Если референсная зона состоит из одной границы, то укажите ее в нужном столбце. Например, референсная зона «выше 30». Цифру 30 надо вставить только в первое поле. </div>
    
    <div v-if="!isEnum" class="analyze-form__row-refs">
      <div v-if="!checkboxes.noRefs" class="analyze-form__refs">
        <FormInput placeholder="Мин." label="Референсные зоны" v-model="result.ranges.min" name="analyze-form__ref-min"/>
        <div class="q-mb-md gt-md"> — </div>
        <FormInput placeholder="Макс." v-model="result.ranges.max" name="analyze-form__ref-max" />
      </div>
      <CheckboxInput v-model="checkboxes.noRefs" class="q-mb-md" label="Нет референсных значений" @input="resetRefs($event)"/>
    </div>

    <div class="analyze-form__row-date">
      <InputDate
      :value="result.date"
      @change-value="result.date = $event"
      format="dd-MM-yyyy"
      :tempVarFormatDataToServer="true"
      label="Дата исследования" 
      v-model="result.date" 
      placeholder="__.__.____"/>
      <FormInput label="Лаборатория" type="select" placeholder="Выберите лабораторию" v-model="currentLaboratoryOption"  :options="laboratoriesList" name="analyze-form__result"/>
    </div>
    </template>


    <FormInput :focus="withFocusOnComment" label="Комментарий" placeholder="Введите ваш комментарий" v-model="result.comment" name="analyze-form__comment" type="textarea"/>
    
    <BaseBtn type="dark" size="sm" @click="postResult" :disabled="loading">{{btnText}}</BaseBtn>

    <!--  TEMP  -->
    <!-- <pre class="TEMP _data">{{$data}}</pre>
    <div class="TEMP">
      <q-radio v-model="formType" val="int" label="Количественный 1" />
      <q-radio v-model="formType" val="dec" label="Количественный 2" />
      <q-radio v-model="formType" val="enum" label="Качественный" />
    </div> -->
  </div>
</template>

<script>
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import FormInput from '@/components/UI/inputs/FormInput.vue';
import InputDate from '@/components/InputDate.vue';
import { format } from 'date-fns';
import { serverDateFormat } from '@/interfaces/api.interface';
import {rounding} from '@/utils/count-helpers.js';
import {showServerError, showClientError} from '@/utils/showNotify.js'



export default {
  data(){
    return {
      isNew: null,
      formType: this.formProp.type,
      result: {
        biomarker_group_id: null,
        value: null,
        comment: null,
        date: this.formProp.date || format(new Date(), 'yyyy-MM-dd'),
        ranges: {
          min: this.formProp.ranges ? rounding(this.formProp.ranges.min) : null,
          max: this.formProp.ranges ? rounding(this.formProp.ranges.max) : null,
        },
        laboratory_id: this.formProp.laboratory_id || null, // TODO check this
        unit_id: this.formProp.unit_id || this.formProp.available_units?.[0] ||this.$store.state.staticVariables.units[0].id, //check

        // NEW FIELDS
        power_value: null,
        num_value: null,
        rel_value: null,
      },
      checkboxes: {
        power_value: false, 
        num_value: false, 
        rel_value: false, 
        noRefs: false,
      },
      currentLaboratoryOption: this.defaultOption(
        { id: this.formProp.laboratory_id,
          name: this.formProp.laboratory },
          null
        ),
      currentUnitOption: this.defaultOption(
        { id: this.formProp.unit_id,
          name: this.formProp.unit },
          this.$store.state.staticVariables.units[0]
        ),
      enumOptions: [],
      currentEnumValueOption: null,
      loading: false,
      userStarted: false,
    }
  },
  computed: {
    isEnum() {
      return this.formType === 'enum'
    },
    unitOptions() {
      const {available_units} = this.formProp
      if (available_units?.length) return available_units
      if (this.availableUnits) return this.availableUnits
      else return this.unitsList
    },
    btnText(){
      if (this.loading) return 'Сохранение...'
      else return this.isNew ? 'Добавить анализ' : 'Сохранить изменения'
    },
    laboratoriesList() {
      return this.$store.state.staticVariables.labs;
    },
    unitsList() {
      return this.$store.state.staticVariables.units;
    }
  },
  methods: {
    userStartedManualAdding(){
      if(this.isNew && !this.userStarted){
        this.userStarted = true
        this.$metrika.reachGoal('userStartedManualAdding')
      }
    },
    userSuccessManualAdding(){
      if(this.isNew){
        this.$metrika.reachGoal('userSuccessManualAdding')
      }
    },
    valueFilter(val){
      const valString = val.toString()
      const regexp = /[^\d\.]/g
      // TODO refact
      setTimeout(() => {
        this.result.value = valString.replaceAll(',', '.').replaceAll(regexp, '');
      }, 1);
    },
    powerValueFilter(val){
      const valString = val.toString().slice(0,4)
      const regexp = /[^\d\.]/g

      // TODO refact
      setTimeout(() => {
        this.result.power_value = valString.replaceAll(regexp, '');
      }, 1);
    },
    setDefaultValues(){
      //  TODO THINK ABOUT REFACTOR - привести formProp к единому виду ?
      const isNew = !this.formProp.biomarker_group_id
      const {formProp, result, checkboxes} = this
      this.isNew = isNew

      result.biomarker_group_id = isNew ? formProp.id : formProp.biomarker_group_id
      result.value = isNew ? null : rounding(formProp.value)
      result.comment = isNew ? null : formProp.comment

      result.power_value = this.isNew ? null : formProp.power_value
      result.num_value = this.isNew ? null : formProp.num_value
      result.rel_value =this.isNew ? null : formProp.rel_value
      
      checkboxes.power_value = this.isNew ? false : !!formProp.power_value // TODO ZERO CASE
      checkboxes.num_value = this.isNew ? false : !!formProp.num_value // TODO ZERO CASE
      checkboxes.rel_value = this.isNew ? false : !!formProp.rel_value // TODO ZERO CASE

      this.enumOptions = this.isNew ? formProp.unique_enum_values : formProp.biomarker_enum_values
    },
    defaultOption(existDefaultOption, commonDefaultOption){
      if ( existDefaultOption.id && existDefaultOption.name) {
        return existDefaultOption
      } else {
        return commonDefaultOption;
      }
    },
    async postResult() {
      if (this.result.value === null || this.result.value === '' ){
        showClientError('Вы должны ввести результат')
        return
      }
      // const rangesIsInvalid = !this.checkboxes.noRefs && (this.result.ranges.min === '' || this.result.ranges.max === '')
      // if (rangesIsInvalid){
      //   showClientError('Введите референсные зоны или поставьте галочку, что их нет')
      //   return
      // }
      if (this.result.ranges?.min === '') this.result.ranges.min = null
      if (this.result.ranges?.max === '') this.result.ranges.max = null

      try {
        if (this.documentId) {
          this.result.document_id = this.documentId
        }
        this.loading = true
        const res = await this.$store.dispatch('analyzes/saveBiomarkers',
          {
            response: this.result,
            id: this.isNew ? null : this.formProp.id, 
            method: this.isNew ? 'POST' : 'PATCH'
          }
        );
        if (res.status) {
          // TODO @/utils/showNotify.js
          this.$q.notify({
            message: `Анализ успешно ${this.isNew ? 'добавлен' : 'изменен'}`,
            type: 'positive',
          });
          // this.$store.commit('analyzes/addAnalyzes', this.result);
          this.userSuccessManualAdding()
          this.$emit('close', 'success')
          this.$emit('add', {data: res.data, isNew: this.isNew} )
        }
      } catch (error) {
        showServerError(error)
    } finally {
      this.loading = false
    }
    },
    resetRefs(value){
      if (value) {
        this.result.ranges = null
      } else {
        this.result.ranges = {min: '', max: ''}
      }

    }
  },
  watch: {
    currentLaboratoryOption(newValue) {
      if (newValue !== null) {
        this.result.laboratory_id = newValue.id
      } 
    },
    currentUnitOption(newValue) {
      this.result.unit_id = newValue.id
      if (!this.formProp.value) {
        const newValueCoeff = this.formProp.available_units.find(item => item.id === newValue.id)?.coeff
        this.result.ranges.min = rounding(newValueCoeff * this.formProp.ranges.min)
        this.result.ranges.max = rounding(newValueCoeff * this.formProp.ranges.max)
      }
    },
    currentEnumValueOption(newValue) {
      this.result.value = newValue.id
    },


  },
  // TODO withFocusOnComment -> withFocusOn
  props: ['formProp', 'handle', 'documentId', 'withFocusOnComment', 'availableUnits'],
  components: {BackBtn,CheckboxInput,InputDate,FormInput},
  mounted() {
    this.setDefaultValues()
    // this.$refs.comment.focus()
  }
}
</script>

<style lang="scss">
$analyze-form__gap: 20px;
.analyze-form {
  background: white;
  height: auto;
  width: 816px;
  max-width: 816px!important;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 14px !important;
  gap: $analyze-form__gap;
  > * {
    flex-shrink: 0;
  }
  .form-input--area {
    @include media-down($breakpoint-md) {
      width: 100%
    }
  }
}

.analyze-form__name {
  padding: 10px 14px;
  background: $light-background;
  color: $black-02;
  border-radius: 14px;
  max-width: 450px;
}

.analyze-form__row-values {
  display: grid;
  gap: $analyze-form__gap;
  grid-template-columns: 215px 155px 215px;
  &._enum {
    grid-template-columns: 275px 215px 155px;
  }
  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
    width: 100%;
  }
}

.analyze-form__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.analyze-form__row-refs {
  display: grid;
  gap: $analyze-form__gap;
  grid-template-columns: auto auto;
  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
    width: 100%;
  }
  align-items: flex-end
}

.analyze-form__row-date {
  display: grid;
  gap: $analyze-form__gap;
  grid-template-columns: auto auto;
  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
    width: 100%;
  }
  align-items: flex-end
}

.analyze-form__row-date {
  display: grid;
  gap: $analyze-form__gap;
  grid-template-columns: 215px 333px;
  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
  }
}

.analyze-form__refs {
  display: grid;
  gap: 6px;
  grid-template-columns: 155px auto 155px;
  align-items: flex-end;
  @include media-down($breakpoint-md) {
    grid-template-columns: auto;
  }
}

.analyze-form__comment {
  width: 632px;
  @include media-down($breakpoint-md) {
    width: auto;
  }
}

.analyze-form__tip {
  max-width: 567px;
  color: $black-03;
}

.TEMP {
  position: absolute;
  left: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  width: min-content;
  padding: 10px;
  &._data {
    right: 20px;
    left: auto
  }
}
// TODO
.analyze-form__power {
  input {
    padding-left: 37px !important;
  }
  .q-field__suffix{
    position: absolute;
    top: 5px;
    left: 14px;
  }
}
</style>