import Vue from 'vue';
import { subDays } from 'date-fns';
export default {
    namespaced: true,
    state: {
        selectedPeriod: 2,
        selectedPeriodDateRange: [subDays(new Date(), 90), new Date()],
        selectedLaboratories: {},
        selectedVisibleResults: [],
        laboratoriesList: [],
    },
    mutations: {
        setPropertyInStore(state, { name, value }) {
            Vue.set(state, name, value);
        },
    },
    actions: {
        setSelectedLaboratories({ commit }, array) {
            commit('setPropertyInStore', { name: 'laboratoriesList', value: array });
            const values = {};
            const addValue = (arr) => {
                arr.forEach((el) => {
                    el.id !== null && (values[el.id] = false);
                });
            };
            addValue(array);
            commit('setPropertyInStore', { name: 'selectedLaboratories', value: values });
        },
    },
    getters: {},
};
