import request from '@/utils/request.js';

export const getOrderedServices = async () => {
  const res = await request({
    method: 'get',
    url: `/ordered-services/`,
  });
  return res.data;
};

export const getOrderedService = async ({ ordered_service_id }) => {
  const res = await request({
    method: 'get',
    url: `/ordered-services/${ordered_service_id}/`,
  });
  return res.data;
};

export const getOrderedServiceResults = async ({ ordered_service_id, count, offset }) => {
  const res = await request({
    method: 'get',
    url: `/ordered-services/${ordered_service_id}/results/`,
    params: { count, offset },
  });
  return res.data.results;
};
