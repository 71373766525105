<template lang="pug">
.DicsountHelloModal
  MessageModal(v-bind="$attrs" v-on="$listeners" size="auto")
    template(#body): .DicsountHelloModal-Content
      img(src="@/assets/discount_modal.jpg").DicsountHelloModal-Image
      .DicsountHelloModal-Title Посмотрите на нашу витрину со скидками и <br/>предложениями!
      .DicsountHelloModal-Text Тут вы можете найти свою персональную скидку на <br/>исследования в ЛабСтори и ПанорамаМед, а также скидочые и <br/>акционные предложения от наших партнеров.
    template(#buttons)
      BaseBtn(size="sm" @click="$router.push({name: 'discountPage'})") Перейти к витрине
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';

export default {
  name: 'DicsountHelloModal',
  components: {
    MessageModal
  }
}
</script>

<style lang="scss">
.DicsountHelloModal {
  
}


.DicsountHelloModal-Content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.DicsountHelloModal-Image {
  height: 190px;
}

.DicsountHelloModal-Title {
  font-size: 16px;
  line-height: 21px;
  color: #2D2D2D;
  font-weight: 500;
}

.DicsountHelloModal-Text {
  font-size: 14px;
  line-height: 20px;
  color: #2D2D2D;
}
</style>