import { __decorate } from "tslib";
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import ManualAddition from '@/components/modals_new/complex/ManualAddition.vue';
import LockLabel from '@/components/LockLabel.vue';
let AddResearch = class AddResearch extends Vue {
    constructor() {
        super(...arguments);
        this.isShowManualAdditionModal = false;
    }
    openAddAnalyzesModal() {
        // ВЫПИЛЕНО
        bus.$emit(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_MODAL);
    }
    openAddFileModal() {
        return true;
    }
    openAddAnalyzeFileModal() {
        return true;
    }
};
__decorate([
    Prop({ type: Boolean })
], AddResearch.prototype, "isModal", void 0);
__decorate([
    Emit('open-file-modal')
], AddResearch.prototype, "openAddFileModal", null);
__decorate([
    Emit('open-analyze-file-modal')
], AddResearch.prototype, "openAddAnalyzeFileModal", null);
AddResearch = __decorate([
    Component({
        components: {
            ManualAddition,
            LockLabel
        },
    })
], AddResearch);
export default AddResearch;
