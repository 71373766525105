import { __decorate } from "tslib";
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import { format } from 'date-fns';
import locale from 'date-fns/locale/ru';
let DatePicker = class DatePicker extends Vue {
    constructor() {
        super(...arguments);
        this.date = '2021/02/20';
        this.submitResult = [];
        this.periodValue = '';
    }
    mounted() {
        this.periodValue = this.range
            ? {
                from: '',
                to: '',
            }
            : this.value || format(new Date(), 'yyyy/MM/dd');
    }
    get title() {
        return this.range
            ? `С ${this.periodValue?.from ? format(new Date(this.periodValue?.from), 'dd MMMM yyyy', { locale }) : '-'} по ${this.periodValue?.to ? format(new Date(this.periodValue?.to), 'dd MMMM yyyy', { locale }) : '-'}`
            : this.periodValue && typeof this.periodValue === 'string'
                ? `${format(new Date(this.periodValue), 'dd MMMM yyyy', { locale })}`
                : '-';
    }
    applyChanges() {
        return this.periodValue;
    }
    changePeriod(obj) {
        this.applyOnChange && this.applyChanges();
        return obj;
    }
    goBack() {
        this.$router.go(-1);
    }
    disableFutureChoiceOptions(date) {
        const today = format(new Date(), 'yyyy/MM/dd');
        return date <= today;
    }
    optionsFn2(date) {
        const parts = date.split('/');
        return parts[2] % 2 === 0;
    }
};
__decorate([
    Prop({ default: format(new Date(), 'yyyy/MM/dd') })
], DatePicker.prototype, "value", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "range", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "applyOnChange", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "showHeader", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "showFooter", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "autoHeight", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "disableDatePicker", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], DatePicker.prototype, "disableFutureChoice", void 0);
__decorate([
    Emit('change-period')
], DatePicker.prototype, "applyChanges", null);
DatePicker = __decorate([
    Component({
        components: {
            BackBtn,
        },
    })
], DatePicker);
export default DatePicker;
