var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-select__wrapper"},[(_vm.labelTitle)?_c('label',{staticClass:"main-select-label"},[_vm._v(" "+_vm._s(_vm.labelTitle)+" ")]):_vm._e(),_c('q-select',{ref:"select",class:['main-select', { 'main-select--opened': _vm.isIconReverse, 'main-select--disable-offset': _vm.disableOffset }],style:({
      backgroundColor: _vm.bcgColor,
      borderColor: _vm.borderColor,
      width: _vm.width + 'px',
      height: _vm.height + 'px',
      minWidth: _vm.minWidth + 'px',
      maxWidth: _vm.maxWidth + 'px',
    }),attrs:{"popup-content-class":"main-select-option","popup-content-style":{ width: _vm.qMenuWidth + 'px' },"options-selected-class":"options-selected","hide-dropdown-icon":"","map-options":_vm.mapOptions,"label":!_vm.isIconReverse ? _vm.selectLabel : '',"option-label":_vm.optionLabel,"value":_vm.value,"option-value":_vm.optionValue,"options":_vm.options,"multiple":_vm.multiple,"disable":_vm.disabled,"virtual-scroll-item-size":0,"behavior":"menu"},on:{"input":_vm.inputSelect,"popup-show":_vm.popupShow,"popup-hide":_vm.popupHide},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_vm._t("prepend")]},proxy:true},{key:"append",fn:function(){return [_vm._t("append",function(){return [_c('icon',{staticClass:"main-select-append-icon",class:{ 'main-select-append-icon-reverse': _vm.isIconReverse },attrs:{"name":"select-icon"}})]})]},proxy:true},(_vm.multiple)?{key:"option",fn:function(ref){
    var itemProps = ref.itemProps;
    var itemEvents = ref.itemEvents;
    var opt = ref.opt;
    var selected = ref.selected;
    var toggleOption = ref.toggleOption;
return [_c('q-item',_vm._g(_vm._b({},'q-item',itemProps,false),itemEvents),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label || opt)}})],1),_c('q-item-section',{attrs:{"side":""}},[_c('checkbox-input',{attrs:{"value":selected},on:{"change-value":function($event){return toggleOption(opt)}}})],1)],1)]}}:null,{key:"selected",fn:function(){return [_c('span',{staticClass:"selected-label"},[_vm._v(_vm._s(_vm.value && _vm.value[_vm.optionLabel] || _vm.placeholder))])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }