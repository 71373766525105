import { __decorate } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import BaseFormMixins from '@/mixins/base-form-mixins';
let AddComment = class AddComment extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.comment = '';
    }
    get id() {
        return this.$store.state.analyzes.commentAnalyzesId;
    }
    async editComment(comment) {
        await this.$store.dispatch('analyzes/addComment', { id: this.id, comment });
        await bus.$emit(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, this.id);
        typeof this.id === 'number' && this.saveComment();
    }
    saveComment() {
        try {
            let method = 'POST';
            if (typeof this.id === 'number') {
                method = 'PATCH';
            }
            const response = this.createDataForSave();
            this.$store.dispatch('analyzes/saveBiomarkers', { ...response, method });
        }
        catch (error) {
            if (error?.errorData?.message) {
                this.$store.dispatch('error/showErrorNotice', { message: error.errorData.message });
            }
        }
    }
    createDataForSave() {
        const data = {
            response: {
                comment: this.comment ? this.comment : this.data.comment,
            },
            id: this.data?.id || this.id,
        };
        return data;
    }
    onClose() {
        return false;
    }
};
__decorate([
    Prop({ required: true, default: () => false })
], AddComment.prototype, "isShow", void 0);
__decorate([
    Prop({})
], AddComment.prototype, "data", void 0);
__decorate([
    Emit('close')
], AddComment.prototype, "onClose", null);
AddComment = __decorate([
    Component({
        components: {
            MainBtn,
            CloseBtn,
        },
    })
], AddComment);
export default AddComment;
