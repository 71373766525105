import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/layout/Header.vue';
import Aside from '@/components/layout/Aside.vue';
import Footer from '@/components/layout/Footer.vue';
import FloatingActionBtn from '@/components/FloatingActionBtn.vue';
import { ILayout } from '@/interfaces/layout.interface';
import AddAnalyzesModal from '@/components/modals/AddAnalyzes.vue';
import ManualAddition from '@/components/modals_new/complex/ManualAddition.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import EditAnalyzes from '@/components/modals/EditAnalyzes.vue';
import checkUserRequiredData from '@/checkUserRequiredData.js';
import DiscountHelloModal from '@/views/DiscountPage/DiscountHelloModal.vue';
import { userFirstVisit } from '@/utils/utils.ts';
var LayoutPattern = ILayout.LayoutPattern;
var Breakpoint = ILayout.Breakpoint;
let DefaultLayout = class DefaultLayout extends Vue {
    constructor() {
        super(...arguments);
        this.activePattern = LayoutPattern.DESKTOP;
        this.breakpoint = Breakpoint;
        this.layoutPattern = LayoutPattern;
        this.drawer = false;
        this.addAnalyzesModalState = false;
        this.editAnalyzesModalState = false;
        this.editData = {};
        this.helloModalIsActive = false;
    }
    async mounted() {
        this.setPattern(window.screen.width);
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_MODAL, () => (this.addAnalyzesModalState = !this.addAnalyzesModalState));
        bus.$on(IAnalyzes.BusEvents.EDIT_ANALYZES, (data) => {
            this.editData = data;
            this.editAnalyzesModalState = !this.editAnalyzesModalState;
        });
        if (this.isUserLoggedIn)
            await this.$store.dispatch('personalArea/init');
        checkUserRequiredData();
        this.checkDiscountModalShowed();
    }
    checkDiscountModalShowed() {
        setTimeout(() => {
            const discountModalNotShowed = userFirstVisit({ localStorageItem: 'discountModalAlreadyShowed', id: true });
            if (discountModalNotShowed) {
                this.helloModalIsActive = true;
            }
        }, 20000);
    }
    get isUserLoggedIn() {
        return this.$store.getters['auth/isLoggedIn'];
    }
    addAnalyzesModalClose(value) {
        this.addAnalyzesModalState = value;
    }
    onResize() {
        this.setPattern(window.screen.width);
    }
    setPattern(windowSize) {
        this.activePattern = windowSize <= Breakpoint.MOBILE ? LayoutPattern.MOBILE : LayoutPattern.DESKTOP;
    }
    editModalClose(value) {
        this.editAnalyzesModalState = value;
    }
};
DefaultLayout = __decorate([
    Component({
        components: {
            Header,
            Aside,
            Footer,
            FloatingActionBtn,
            AddAnalyzesModal,
            ManualAddition,
            EditAnalyzes,
            DiscountHelloModal
        },
    })
], DefaultLayout);
export default DefaultLayout;
