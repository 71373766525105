import { __decorate } from "tslib";
import { Component, Emit, Prop } from 'vue-property-decorator';
import BaseFormMixins from '@/mixins/base-form-mixins';
;
import DatePicker from '@/components/UI/DatePicker.vue';
let InputDate = class InputDate extends BaseFormMixins {
    constructor() {
        super(...arguments);
        this.date = '';
        this.isDateModalOpen = false;
    }
    mounted() {
        this.date = this.value;
    }
    changeValue(value) {
        this.date = value;
        return this.tempVarFormatDataToServer ? value.replaceAll('/', '-') : value;
    }
    toggleDateModal(val) {
        this.isDateModalOpen = val;
    }
    validate() {
        return this.$refs.dateInput.validate();
    }
};
__decorate([
    Prop({ required: false, default: '' })
], InputDate.prototype, "value", void 0);
__decorate([
    Prop({ default: 'dd.MM.yyyy' })
], InputDate.prototype, "format", void 0);
__decorate([
    Prop()
], InputDate.prototype, "label", void 0);
__decorate([
    Prop({ default: 'Введите значение' })
], InputDate.prototype, "placeholder", void 0);
__decorate([
    Prop({ default: 'input-date' })
], InputDate.prototype, "customClass", void 0);
__decorate([
    Prop({ default: () => [] })
], InputDate.prototype, "propRules", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], InputDate.prototype, "disableDatePicker", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], InputDate.prototype, "disableFutureChoice", void 0);
__decorate([
    Prop({ default: false, type: Boolean })
], InputDate.prototype, "tempVarFormatDataToServer", void 0);
__decorate([
    Emit('change-value')
], InputDate.prototype, "changeValue", null);
InputDate = __decorate([
    Component({ components: { DatePicker } })
], InputDate);
export default InputDate;
