export var IAuthForOtherUser;
(function (IAuthForOtherUser) {
    let RegistrationSteps;
    (function (RegistrationSteps) {
        RegistrationSteps["CHECK_USER"] = "checkUser";
        RegistrationSteps["CHECK_PHONE"] = "checkPhone";
        RegistrationSteps["SET_PASSWORD"] = "setPassword";
        RegistrationSteps["LOGIN_BY_ID"] = "loginById";
        RegistrationSteps["HAS_LOGIN"] = "hasLogin";
    })(RegistrationSteps = IAuthForOtherUser.RegistrationSteps || (IAuthForOtherUser.RegistrationSteps = {}));
})(IAuthForOtherUser || (IAuthForOtherUser = {}));
export var IAuth;
(function (IAuth) {
    let AuthMode;
    (function (AuthMode) {
        AuthMode["REGISTRATION"] = "register";
        AuthMode["LOGIN"] = "login";
    })(AuthMode = IAuth.AuthMode || (IAuth.AuthMode = {}));
})(IAuth || (IAuth = {}));
export var IAuthApi;
(function (IAuthApi) {
    let CheckUserParamsType;
    (function (CheckUserParamsType) {
        CheckUserParamsType["EMAIL"] = "email";
        CheckUserParamsType["PHONE"] = "phone";
        CheckUserParamsType["ID"] = "patient_id";
    })(CheckUserParamsType = IAuthApi.CheckUserParamsType || (IAuthApi.CheckUserParamsType = {}));
    let ReasonOnSendCodeType;
    (function (ReasonOnSendCodeType) {
        ReasonOnSendCodeType["AUTH"] = "auth";
        ReasonOnSendCodeType["APPROVE_EMAIL"] = "approve_email";
        ReasonOnSendCodeType["APPROVE_PHONE"] = "approve_phone";
    })(ReasonOnSendCodeType = IAuthApi.ReasonOnSendCodeType || (IAuthApi.ReasonOnSendCodeType = {}));
    let AuthType;
    (function (AuthType) {
        AuthType["LOGIN"] = "login";
        AuthType["REGISTER"] = "register";
    })(AuthType = IAuthApi.AuthType || (IAuthApi.AuthType = {}));
})(IAuthApi || (IAuthApi = {}));
export const cookeTokenKey = 'refreshToken';
