<template>
  <div class="form-input" :class="{'form-input--area' : type === 'textarea'}">
    <label class="main-select-label" :class="name" :for="name">{{label}}</label>
      <MainSelect
        v-if="type == 'select'"
        :value="value"
        :options="options"
        :placeholder="placeholder"
        :optionLabel="optionLabel"
        @input-select="$emit('input', $event)"/>
        <!-- :selectLabel="placeholder" -->
      <q-input
        v-bind="$attrs"
        v-on="$listeners"
        :suffix="suffix"
        ref="input"
        :placeholder="placeholder"
        :prefix="prefix"
        v-else
        :type="type"
        :value="value" 
        :id="name"
        @input="$emit('input', $event)"

      />
              <!-- v-bind="$attrs"
        v-on="$listeners" -->

  </div>
</template>

<script>
export default {
  components: {
    MainSelect: () => import('@/components/UI/MainSelect.vue')
  },
  props: {
    value: {
      type: [String, Number, Object],
    },
    name: {
      type: String,
      required: true
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    label: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    prefix: {
      type: String,
    },
    placeholder: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
    },
    type: {
      type: String,
      default: 'input'
    },
    focus: {
      type: Boolean,
      default: false
    },
  },
  mounted(){
    if( this.focus) {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">
.main-select-label {
  display: block;
  font-weight: 500;
  font-size: 13px;
  color: $black-02;
  margin-bottom: 8px;
}
</style>