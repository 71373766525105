import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import clickOutside from '@/directives/click-outside';
import ManualAddition from '@/components/modals_new/complex/ManualAddition.vue';
import LockLabel from '@/components/LockLabel.vue';
let FloatingActionBtn = class FloatingActionBtn extends Vue {
    constructor() {
        super(...arguments);
        this.showActions = false;
        this.documentModalState = false;
        this.actions = [
            {
                icon: 'cloud-icon',
                text: 'Загрузить файл анализа',
                handler: this.uploadFile,
                name: 'upload',
            },
            {
                icon: 'finger-icon',
                text: 'Загрузить анализ вручную',
                handler: this.addFile,
                name: 'add',
            },
            {
                icon: 'document-icon',
                text: 'Другой документ',
                handler: this.toggleDocumentModal,
                name: 'other',
            },
        ];
        this.isShowManualAdditionModal = false;
    }
    uploadFile() {
        bus.$emit(IAnalyzes.BusEvents.TOGGLE_DOCUMENT_MODAL, {
            decipherAnalyzes: false,
            isAnalyzeDownload: true,
        });
        this.closeActions();
    }
    addFile() {
        this.isShowManualAdditionModal = true;
        // TODO ManualAddition
        // if (this.$route.name !== ROUTE_NAME.ADD_ANALYZES) this.$router.push({ name: ROUTE_NAME.ADD_ANALYZES });
        // this.closeActions();
    }
    toggleDocumentModal() {
        bus.$emit(IAnalyzes.BusEvents.TOGGLE_DOCUMENT_MODAL, {
            decipherAnalyzes: true,
            isAnalyzeDownload: false,
        });
        this.closeActions();
    }
    toggleActions() {
        this.showActions = !this.showActions;
    }
    closeActions() {
        this.showActions = false;
    }
};
FloatingActionBtn = __decorate([
    Component({
        directives: {
            clickOutside,
        },
        components: { ManualAddition, LockLabel }
    })
], FloatingActionBtn);
export default FloatingActionBtn;
